import { gql } from '@apollo/client';

export const GET_AGENCY_INVITES = gql`
  query getAgencyInvites($agencyId: Int!) {
    agencyInvites(agencyId: $agencyId) {
      id
      agencyId
      token
      email
      role
      invitedAt
      expiresAt
      token
    }
  }
`;

export const RESEND_AGENCY_INVITE = gql`
  mutation resendInvite($id: Int!) {
    resendInvite(id: $id)
  }
`;
