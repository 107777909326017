import { ApolloQueryResult, gql, OperationVariables, useMutation } from '@apollo/client';
import { ReactComponent as AlertSVG } from 'assets/svg/warning.svg';
import Modal from 'components/modal/modal';
import RenderModal from 'components/modal/render-modal';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  user: DBUser;
  agencyId: number;
  setActionsVisible: (visible: boolean) => void;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<{ [key: string]: { data: DBUser[]; count: number } }>>;
}

export const REMOVE_USER = gql`
  mutation removeUser($userId: Int!, $agencyId: Int!) {
    removeUser(userId: $userId, agencyId: $agencyId)
  }
`;

const RemoveUser: React.FC<Props> = ({ user, agencyId, setActionsVisible, refetch }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [removeUser] = useMutation(REMOVE_USER);
  const confirm = () => {
    void removeUser({ variables: { userId: Number(user.id), agencyId: Number(agencyId) } }).then(() => {
      setActionsVisible(false);
      setModalOpen(false);
      void refetch();
    });
  };
  return (
    <>
      <Remove onClick={() => setModalOpen(true)} disabled={user.role === 'owner'} data-testid='remove-user__button'>
        Remove
      </Remove>
      <RenderModal>
        <Modal open={modalOpen} title='Remove User' onClose={() => setModalOpen(false)} height='239px' icon={<Alert />}>
          <ContentWrapper data-testid='remove-user__modal'>
            Are you sure you want to remove {user.name}? Contact Support at (844) 560-2626 to recieve further help around any issues you may
            have.
            <Button onClick={confirm} data-testid='remove-user__confirm'>
              Remove User
            </Button>
          </ContentWrapper>
        </Modal>
      </RenderModal>
    </>
  );
};

const Remove = styled.button`
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.mediumStrawberry};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  background: transparent;
  cursor: pointer;
  :disabled {
    color: ${({ theme }) => theme.colors.silver200};
    cursor: initial;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 32px 30px;
  font-size: 16px;
  line-height: 24px;
`;

const Button = styled.button`
  width: 115px;
  height: 34px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.mediumPink};
  color: white;
  border: none;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-top: 50px;
  cursor: pointer;
`;

const Alert = styled(AlertSVG)`
  margin-right: 11px;
`;
export default RemoveUser;
