import React, { useState } from 'react';

import { StyledLinkIcon, CopyLinkButton } from './styles';

export interface Props {
  token: string;
}

const CopyInviteLink: React.FC<Props> = ({ token }) => {
  const [isInviteCopied, setIsInviteCopied] = useState(false);

  const copyAcceptInviteLink = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'http://localhost:3000';
    void navigator.clipboard.writeText(`${baseUrl}/accept-invite/${token}`);
    setIsInviteCopied(true);
    setTimeout(() => {
      setIsInviteCopied(false);
    }, 2000);
  };

  return (
    <CopyLinkButton
      onClick={() => {
        copyAcceptInviteLink();
      }}
      data-testid='pending-invites__li-copy-link-button'
      disabled={isInviteCopied}
      isInviteCopied={isInviteCopied}
    >
      <StyledLinkIcon />
      {isInviteCopied ? (
        <span data-testid='pending-invites__li-copy-link-button-text'>copied</span>
      ) : (
        <span data-testid='pending-invites__li-copy-link-button-text'>copy link</span>
      )}
    </CopyLinkButton>
  );
};

export default CopyInviteLink;
