import { useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { CUser } from 'cognito.types';
import TextInput from 'components/form-elements/text-input/text-input';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ErrorMessage } from 'routes/login/styles';
import { setTokenAction } from 'state/reducers/auth-reducer';
import { useAppDispatch } from 'state/state-hooks';
import { CognitoPasswordRequirements } from '../../utils/password-validation/password-validation.types';
import { usePasswordValidation, validatePassword, prettifyErrorMessage } from '../../utils/password-validation/';

import { CONFIRM_FORGOT_PASSWORD } from './settings';

import { Button, FormLabel, ConfirmForgotPasswordWrapper, SmallLogo, ResetPasswordDescription, ResetPasswordHeader } from './styles';

const ConfirmForgotPassword: React.FC = () => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<string>();
  const [error, setError] = useState('');

  const { email } = useParams();

  const dispatch = useAppDispatch();
  const [user, setUser] = useState<CUser>();
  const passwordValidation = usePasswordValidation();

  useEffect(() => {
    if (user) {
      dispatch(
        setTokenAction({ idToken: user.signInUserSession.idToken.jwtToken, refreshToken: user.signInUserSession.refreshToken.token })
      );
    }
  }, [user]);

  const [confirmForgotPassword, { loading }] = useMutation<{ resetForgotPassword: { isSucceed: boolean; errCode: string | null } }>(
    CONFIRM_FORGOT_PASSWORD,
    {
      variables: { email, code, password: newPassword },
    }
  );

  const passwordsMatch = newPassword === confirmPassword;

  const checkPasswords = () => {
    if (!passwordsMatch) {
      setNewPasswordError('Passwords do not match');
    }
  };

  const loginUser = async (email: string, password: string) => {
    try {
      const user = (await Auth.signIn(email, password, { portal: 'agency' })) as CUser;
      setUser(user);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setNewPasswordError('Passwords do not match');
      return;
    }

    const errors = validatePassword(newPassword, passwordValidation as CognitoPasswordRequirements);

    if (errors.length) {
      setNewPasswordError(prettifyErrorMessage(passwordValidation as CognitoPasswordRequirements));
      return;
    }

    const res = await confirmForgotPassword();

    if (res?.data?.resetForgotPassword?.isSucceed) {
      loginUser(email as string, newPassword);
    } else {
      switch (res?.data?.resetForgotPassword?.errCode) {
        case 'CodeMismatchException':
          setError('Verification code is incorrect');
          break;
        case 'ExpiredCodeException':
          setError('Verification code is expired');
          break;
        case 'LimitExceededException':
          setError('User exceeds the limit for reset password');
          break;
        default:
          setError('Something went wrong. Please try again!');
      }
    }
  };

  return (
    <>
      <ConfirmForgotPasswordWrapper data-testid='confirm-forgot-password__wrapper'>
        <SmallLogo />
        <form onSubmit={handleSubmit} style={{ width: 340 }} data-testid='confirm-forgot-password__form'>
          <FormLabel>
            <ResetPasswordHeader>Reset your password.</ResetPasswordHeader>
            <br />
            <ResetPasswordDescription>
              A verification code has been sent to your email. Please enter the code below.
            </ResetPasswordDescription>
          </FormLabel>
          <ErrorMessage>{error}</ErrorMessage>
          <TextInput
            label='Verification Code'
            testid='forgot-password__code-input'
            value={code}
            onChange={e => {
              setCode(e.target.value);
            }}
            placeholder='Verification Code'
            autoComplete='new-password'
            errorText={''}
            required
            width='100%'
            type='text'
          />
          <br />
          <TextInput
            label='New Password'
            testid='confirm-forgot-password__new-password-input'
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
              if (e.target.value === confirmPassword) setNewPasswordError(undefined);
            }}
            placeholder='Password'
            autoComplete='new-password'
            errorText={newPasswordError || ''}
            required
            showError={!!newPasswordError}
            width='100%'
            type='password'
          />
          <br />
          <TextInput
            label='Confirm New Password'
            testid='confirm-forgot-password__confirm-password-input'
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword(e.target.value);
              if (e.target.value === newPassword) setNewPasswordError(undefined);
            }}
            onBlur={checkPasswords}
            placeholder='Password'
            errorText={newPasswordError || ''}
            required
            showError={!!newPasswordError}
            width='100%'
            type='password'
          />
          <Button
            type='submit'
            disabled={!code || !newPassword || !confirmPassword || !passwordsMatch}
            data-testid='confirm-forgot-password__submit-button'
          >
            {loading ? 'Loading...' : 'Reset Password >'}
          </Button>
        </form>
      </ConfirmForgotPasswordWrapper>
    </>
  );
};

export default ConfirmForgotPassword;
