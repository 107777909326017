import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    lightPink: '#F773B2',
    mediumPink: '#E21979',
    darkPink: '#A90F59',
    lightStrawberry: '#F4888E',
    mediumStrawberry: '#EC6168',
    darkStrawberry: '#C84248',
    lightPeach: '#FFE0CA',
    mediumPeach: '#EEB489',
    darkPeach: '#BC8E6B',
    lightSand: '#FBEBCD',
    mediumSand: '#F3D397',
    darkSand: '#E0B86B',
    lightSeaGreen: '#B0FFD9',
    mediumSeaGreen: '#74E7B0',
    darkSeaGreen: '#5CBD8F',
    lightAtlantic: '#91D9E4',
    mediumAtlantic: '#459EAC',
    darkAtlantic: '#388190',
    lightSky: '#9FDCFE',
    mediumSky: '#6AB6E0',
    darkSky: '#3989B5',
    lightPacific: '#5786B3',
    mediumPacific: '#39546E',
    darkPacific: '#243749',
    lightPlum: '#9F5881',
    mediumPlum: '#6A2F51',
    darkPlum: '#471C35',
    silver100: '#F5F5F5',
    silver200: '#E9E9E9',
    silver300: '#D9D9D9',
    silver400: '#B7B7B7',
    silver500: '#797979',
    silver600: '#5A5A5A',
    silver700: '#464646',
    silver800: '#353535',
    silver900: '#242424',
    white: '#FFFFFF',
    black: '#131313',
    bg: '#F6F8FB',
    codGray: '#1E1E1E',
    shuttleGray: '#646C79',
    slateExtraLight: '#D2D8E0',
  },
};

export default theme;
