import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;
`;

export const TitleText = styled.span`
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.silver600};
`;
