import React, { ReactElement } from 'react';

import { Wrapper } from './styles';

interface Props {
  children: ReactElement;
}

const RightPane: React.FC<Props> = ({ children }) => <Wrapper data-testid='right-pane__wrapper'>{children}</Wrapper>;

export default RightPane;
