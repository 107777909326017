import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { AgencyInvite } from './interface';
import { RESEND_AGENCY_INVITE } from './settings';
import { StyledSendIcon, ResendInviteButton, Tooltip, ResendInviteWrapper } from './styles';

export interface Props {
  agencyInvite: AgencyInvite;
  index: number;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      agencyInvites: AgencyInvite[];
    }>
  >;
}

const ResendInvite: React.FC<Props> = ({ agencyInvite, index, refetch }) => {
  const [resendInvite] = useMutation(RESEND_AGENCY_INVITE);
  const [showTooltip, setShowTooltip] = useState(false);

  const resendAgencyInvite = () => {
    void resendInvite({ variables: { id: agencyInvite.id } }).then(() => {
      void refetch();
    });
  };

  const isInviteResendable = () => {
    const invitedAtDateTime = new Date(agencyInvite.invitedAt);
    const resendableDateTime = new Date(invitedAtDateTime.getTime() + 30 * 60000);

    if (resendableDateTime < new Date()) {
      return true;
    }
    return false;
  };

  return (
    <ResendInviteWrapper>
      <Tooltip showTooltip={showTooltip} index={index} data-testid='pending-invites__li-resend-invite-disabled-tooltip'>
        You can resend an invite once every 30 minutes
      </Tooltip>
      <ResendInviteButton
        onClick={() => {
          resendAgencyInvite();
        }}
        data-testid='pending-invites__li-resend-invite-button'
        disabled={!isInviteResendable()}
        isInviteResent={!isInviteResendable()}
      >
        <StyledSendIcon />
        {!isInviteResendable() ? (
          <span
            onMouseEnter={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            data-testid='pending-invites__li-resend-invite-button-text'
          >
            invite sent
          </span>
        ) : (
          <span data-testid='pending-invites__li-resend-invite-button-text'>resend invite</span>
        )}
      </ResendInviteButton>
    </ResendInviteWrapper>
  );
};

export default ResendInvite;
