import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { ReactComponent as ElipsesIconSVG } from 'assets/svg/elipses-icon.svg';
import { UserAgencyRole } from 'enums';
import React, { useState } from 'react';
import { useAppSelector } from 'state/state-hooks';
import styled from 'styled-components';

import RemoveUser from './remove-user';

interface Props {
  user: DBUser;
  agencyId: number;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<{ [key: string]: { data: DBUser[]; count: number } }>>;
}

const Actions: React.FC<Props> = ({ user, agencyId, refetch }) => {
  const currentAuth = useAppSelector(state => state.auth);
  const currentUserEmail = currentAuth.user.attributes.email;
  const [actionsVisible, setActionsVisible] = useState(false);
  return user.role !== UserAgencyRole.owner && user.email !== currentUserEmail ? (
    <Wrapper>
      <ElipsesIcon onClick={() => setActionsVisible(!actionsVisible)} data-testid='actions__elipses' />
      {actionsVisible && (
        <ActionsContainer onMouseLeave={() => setActionsVisible(false)} data-testid='actions__actions-container'>
          <RemoveUser user={user} agencyId={agencyId} setActionsVisible={setActionsVisible} refetch={refetch} />
        </ActionsContainer>
      )}
    </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #b7b7b7;
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
`;
export const ElipsesIcon = styled(ElipsesIconSVG)`
  cursor: pointer;
`;

export const ActionsContainer = styled.div`
  position: absolute;
  width: 106px;
  background: white;
  left: -80px;
  top: 30px;
  padding: 16px 24px;
  z-index: 1;
  :after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: 25px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

export default Actions;
