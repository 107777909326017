import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { setInvitesListChangedAction } from 'state/reducers/invites-modal-reducer';
import { useAppDispatch, useAppSelector } from 'state/state-hooks';
import { REMOVE_AGENCY_INVITE } from 'utils/gql';
import Invite from './agency-invite';
import {
  PendingInviteContainer,
  Wrapper,
  TextContainer,
  InviteListContainer,
  InviteList,
  DeclineInviteContainer,
  ButtonContainer,
  ConfirmDeclineButton,
  AgencyNameSpan,
} from './styles';

const PendingAgencyInvites: React.FC = () => {
  const { user } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const [removeAgencyInvite] = useMutation(REMOVE_AGENCY_INVITE);

  const [isDecliningInvite, setIsDecliningInvite] = useState(false);
  const [agencyInviteToDecline, setAgencyInviteToDecline] = useState<AgencyInvite>();

  const invites = user.attributes.invites || [];

  const handleDeclineInvite = (invite: AgencyInvite) => {
    setAgencyInviteToDecline(invite);
    setIsDecliningInvite(true);
  };

  const confirmDeclineInvite = (agencyInvite: AgencyInvite) => {
    void removeAgencyInvite({ variables: { id: agencyInvite.id } }).then(() => {
      setAgencyInviteToDecline(undefined);
      setIsDecliningInvite(false);
      dispatch(setInvitesListChangedAction(true));
    });
  };

  const cancelDeclineInvite = () => {
    setAgencyInviteToDecline(undefined);
    setIsDecliningInvite(false);
  };

  return (
    <Wrapper data-testid='pending-agency-invites__wrapper'>
      <PendingInviteContainer>
        <TextContainer>You have been invited to join the following Agencies:</TextContainer>
        {isDecliningInvite && agencyInviteToDecline && (
          <DeclineInviteContainer data-testid='pending-agency-invites__remove-confirm'>
            <span>
              Are you sure you want to decline the invitation to join{' '}
              <AgencyNameSpan>{agencyInviteToDecline?.agency?.name}?</AgencyNameSpan>
            </span>
            <ButtonContainer>
              <ConfirmDeclineButton
                onClick={() => confirmDeclineInvite(agencyInviteToDecline)}
                data-testid='pending-agency-invites__remove-confirm-yes'
              >
                Yes
              </ConfirmDeclineButton>
              <ConfirmDeclineButton onClick={() => cancelDeclineInvite()} data-testid='pending-agency-invites__remove-confirm-no'>
                No
              </ConfirmDeclineButton>
            </ButtonContainer>
          </DeclineInviteContainer>
        )}
        <InviteListContainer data-testid='pending-agency-invites__invites-container' count={invites.length}>
          <InviteList>
            {invites.map((invite, index) => (
              <Invite invite={invite} key={index} handleDecline={handleDeclineInvite} />
            ))}
          </InviteList>
        </InviteListContainer>
      </PendingInviteContainer>
    </Wrapper>
  );
};

export default PendingAgencyInvites;
