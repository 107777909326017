import { gql } from '@apollo/client';

export const GET_AGENCY_COMPANIES = gql`
  query getAgencyCompanies($agencyId: Int!, $order: CompanyByAgencyOrder, $search: String, $pagination: PaginatedInput) {
    agencyCompanies(agencyId: $agencyId, order: $order, search: $search, pagination: $pagination) {
      data {
        name
        id
        ownerName
        createdAt
        locationsCount
        providersCount
      }
      totalLocations
      totalProviders
      count
    }
  }
`;
