import { ActionsHeader, Count, Header, ActionsWrapper } from 'components/data-table/styles';
import Modal from 'components/modal/modal';
import { UserAgencyRole } from 'enums';
import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';
import React, { useState } from 'react';
import InviteUsers from 'routes/agency/users/invite-users';
import { medium } from 'styles/typography';

import Role from '../../../components/role/role';
import Search from '../../../components/search/search';

import Actions from './actions';
import { GET_AGENCY_USERS } from './settings';
import { InviteUsersBtn, UtilitiesRow } from './styles';
import styled from 'styled-components';
import DataTable from 'components/data-table';
import { useTableSort } from 'components/data-table/hooks/useTableSort';
import { useTableQuery } from 'components/data-table/hooks/useTableQuery';
import { usePagination } from 'components/data-table/hooks/usePagination';

interface Props {
  agency: Agency;
  role: UserAgencyRole;
}

const Users: React.FC<Props> = ({ agency, role }) => {
  useBreadCrumbUpdate([agency.name, 'Users']);

  const [search, setSearch] = useState('');
  const [isInvitingUsers, setIsInvitingUsers] = useState(false);

  const { currentSortedColumn, sortDirection, handleSort } = useTableSort();
  const { currentPage, setCurrentPage, pageSize, renderPagination } = usePagination();

  const { tableData, count, isLoading, refetch } = useTableQuery(GET_AGENCY_USERS, {
    agencyId: +agency.id,
    pagination: {
      page: currentPage,
      pageSize,
    },
    order: {
      sort: sortDirection,
      field: currentSortedColumn,
    },
    search,
  });

  const handleSearch = (newSearch: string) => {
    setCurrentPage(1);
    setSearch(newSearch);
  };

  const columns = [
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('name')}
          isSortable
          isSorting={currentSortedColumn === 'name'}
          direction={sortDirection}
        >
          Company
        </Header>
      ),
      component: /* istanbul ignore next */ (user: DBUser) => (
        <>
          <UserName>{user.name}</UserName>
        </>
      ),
      columnStyles: `
          width: 199px;
          padding-right: 8px;
        `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('email')}
          isSortable
          isSorting={currentSortedColumn === 'email'}
          direction={sortDirection}
        >
          Email
        </Header>
      ),
      component: /* istanbul ignore next */ (user: DBUser) => <>{user.email}</>,
      columnStyles: `
          width: 195px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('createdAt')}
          isSortable
          isSorting={currentSortedColumn === 'createdAt'}
          direction={sortDirection}
        >
          Date Joined
        </Header>
      ),
      component: /* istanbul ignore next */ (user: DBUser) => <>{new Date(user.createdAt).toLocaleDateString('en')}</>,
      columnStyles: `
          width: 92px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('role')}
          isSortable
          isSorting={currentSortedColumn === 'role'}
          direction={sortDirection}
        >
          Role
        </Header>
      ),
      component: /* istanbul ignore next */ (user: DBUser) => <Role user={user} agency={{ role, id: agency.id }} refetch={refetch} />,
      columnStyles: `
         width: 70px;
      `,
    },
    {
      header: <ActionsHeader>Actions</ActionsHeader>,
      component: /* istanbul ignore next */ (user: DBUser) =>
        role !== UserAgencyRole.member ? (
          <ActionsWrapper>
            <Actions user={user} agencyId={agency.id} refetch={refetch} />
          </ActionsWrapper>
        ) : (
          <></>
        ),
      columnStyles: `
          width: 30px;
      `,
    },
  ];

  return (
    <>
      <Modal
        title='Invite Users'
        open={isInvitingUsers}
        onClose={
          /* istanbul ignore next */ () => {
            setIsInvitingUsers(false);
          }
        }
      >
        <InviteUsers agency={agency} />
      </Modal>
      <UtilitiesRow>
        <Search search={handleSearch} dataTestId='table__data-search' />
        {role !== UserAgencyRole.member && (
          <InviteUsersBtn
            onClick={
              /* istanbul ignore next */ () => {
                setIsInvitingUsers(true);
              }
            }
          >
            + Invite Users
          </InviteUsersBtn>
        )}
      </UtilitiesRow>
      {<Count data-testid='users__count'>{count} Users</Count>}
      <DataTable data={tableData} loading={isLoading} columns={columns} />
      {!isLoading && renderPagination(count)}
    </>
  );
};

const UserName = styled.p`
  ${medium}
  color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.silver900};
  margin-left: 8px;
`;

export default Users;
