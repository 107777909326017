import { useMutation } from '@apollo/client';
import { ReactComponent as LogoSVG } from 'assets/svg/sidebar-logo.svg';
import { CUser } from 'cognito.types';
import HeaderProvider from 'context/header/header.provider';
import { setUserAction } from 'state/reducers/auth-reducer';
import { closeModalAction, setInvitesListChangedAction } from 'state/reducers/invites-modal-reducer';
import { useAppDispatch, useAppSelector } from 'state/state-hooks';
import styled from 'styled-components';
import { GET_CURRENT_USER } from 'utils/gql';

import Header from './header';
import MasterProfile from './master-profile';
import Modal from './modal/modal';
import PendingAgencyInvites from './pending-agency-invites';
import SidebarLinks from './sidebar-links/sidebar-links';

const Layout: React.FC = ({ children }) => {
  const { invitesModal } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const [getCurrentUserDetails] = useMutation<{ getSignedInUserDetails: CUser }>(GET_CURRENT_USER);

  const onModalClose = () => {
    if (invitesModal.invitesListChanged) {
      getUser();
    }
    dispatch(closeModalAction());
  };

  const getUser = async (): Promise<void> => {
    void getCurrentUserDetails()
      .then(res => {
        if (res.data?.getSignedInUserDetails) dispatch(setUserAction(res.data.getSignedInUserDetails));
      })
      .catch(err => console.error(err));

    dispatch(setInvitesListChangedAction(false));
  };

  return (
    <>
      <HeaderProvider>
        <OuterContainer data-testid='layout__wrapper'>
          <Header />
          <SideBar>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <SidebarLinks />
          </SideBar>
          <RightContainer data-testid='layout__right-container'>
            <ContentContainer data-testid='layout__content-container'>{children}</ContentContainer>
          </RightContainer>
          <MasterProfile />
        </OuterContainer>
      </HeaderProvider>
      <Modal title='Pending Invites' open={invitesModal.isOpen} onClose={onModalClose} data-testid='layout__pending-invite-modal'>
        <PendingAgencyInvites />
      </Modal>
    </>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.silver100};
`;

const SideBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 78px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

const LogoContainer = styled.div`
  width: 100%;
  padding: 13px 0 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled(LogoSVG)`
  width: 43px;
  height: 43px;
`;

const RightContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 90px 30px 30px 90px;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem 0 1rem 1rem;
  box-sizing: border-box;
`;

export default Layout;
