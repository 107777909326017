interface PaginationDataObject {
  data: any[];
  count: number;
  totalLocations?: number;
  totalProviders?: number;
}
interface PaginationData {
  [key: string]: PaginationDataObject;
}

export const useTableData = (key: string, loading: boolean, data?: PaginationData, previousData?: PaginationData) => {
  let totalLocations = 0;
  let totalProviders = 0;
  if (loading) {
    if (previousData) {
      totalLocations = previousData[key].totalLocations ? (previousData[key].totalLocations as number) : 0;
      totalProviders = previousData[key].totalProviders ? (previousData[key].totalProviders as number) : 0;

      if (previousData[key].data.length) {
        return {
          tableData: previousData[key].data,
          count: previousData[key].count,
          totalLocations,
          totalProviders,
        };
      }
    }
    return { tableData: [], count: 0, totalLocations, totalProviders };
  }
  if (data) {
    totalLocations = data[key].totalLocations ? (data[key].totalLocations as number) : 0;
    totalProviders = data[key].totalProviders ? (data[key].totalProviders as number) : 0;

    if (data[key].data.length) {
      return {
        tableData: data[key].data,
        count: data[key].count,
        totalLocations,
        totalProviders,
      };
    }
  }

  return { tableData: [], count: 0, totalLocations, totalProviders };
};
