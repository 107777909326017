import DataTable from 'components/data-table';
import { ActionsWrapper, Count, ActionsHeader, Header } from 'components/data-table/styles';
import { KioskIcon } from 'components/icons/kiosk-icon';
import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { medium } from 'styles/typography';

import Search from '../../components/search/search';

import { AllAgenciesReturn } from './interfaces';
import { GET_ALL_AGENCIES } from './settings';
import { Wrapper, CreateAgencyBtn, ControlsWrapper } from './styles';
import styled from 'styled-components';
import Actions from './actions';
import { useTableSort } from 'components/data-table/hooks/useTableSort';
import { usePagination } from 'components/data-table/hooks/usePagination';
import { useTableQuery } from 'components/data-table/hooks/useTableQuery';
import { useLocation } from 'react-router';

const Kiosk: React.FC = () => {
  useBreadCrumbUpdate(['All Agencies'], /* istanbul ignore next */ () => <KioskIcon />);
  const location = useLocation() as { state: { refetch?: boolean } | null };

  const [search, setSearch] = useState('');

  const { currentSortedColumn, sortDirection, handleSort } = useTableSort();
  const { currentPage, setCurrentPage, pageSize, renderPagination } = usePagination();

  const { tableData, count, isLoading, refetch } = useTableQuery(GET_ALL_AGENCIES, {
    pagination: {
      page: currentPage,
      pageSize,
    },
    order: {
      sort: sortDirection,
      field: currentSortedColumn,
    },
    search,
  });

  const handleSearch = (newSearch: string) => {
    setCurrentPage(1);
    setSearch(newSearch);
  };

  const columns = [
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('name')}
          isSortable
          isSorting={currentSortedColumn === 'name'}
          direction={sortDirection}
        >
          Agency
        </Header>
      ),
      component: /* istanbul ignore next */ (agency: AllAgenciesReturn) => (
        <Link to={`/agency/${agency.id}/companies`}>
          <AgencyName>{agency.name}</AgencyName>
        </Link>
      ),
      columnStyles: `
          width: 33.9%;
          padding-right: 8px;
        `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('ownerName')}
          isSortable
          isSorting={currentSortedColumn === 'ownerName'}
          direction={sortDirection}
        >
          Owner Name
        </Header>
      ),
      component: /* istanbul ignore next */ (agency: AllAgenciesReturn) => <>{agency.ownerName}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('createdAt')}
          isSortable
          isSorting={currentSortedColumn === 'createdAt'}
          direction={sortDirection}
        >
          Date Created
        </Header>
      ),
      component: /* istanbul ignore next */ (agency: AllAgenciesReturn) => <>{new Date(agency.createdAt).toLocaleDateString('en')}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('numCompanies')}
          isSortable
          isSorting={currentSortedColumn === 'numCompanies'}
          direction={sortDirection}
        >
          # of Companies
        </Header>
      ),
      component: /* istanbul ignore next */ (agency: AllAgenciesReturn) => <>{agency.numCompanies}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('numUsers')}
          isSortable
          isSorting={currentSortedColumn === 'numUsers'}
          direction={sortDirection}
        >
          # of Users
        </Header>
      ),
      component: /* istanbul ignore next */ (agency: AllAgenciesReturn) => <>{agency.numUsers}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: <ActionsHeader>Actions</ActionsHeader>,
      component: /* istanbul ignore next */ (agency: AllAgenciesReturn) => (
        <ActionsWrapper>
          <Actions agency={agency} currentPage={currentPage} />
        </ActionsWrapper>
      ),
      columnStyles: `
          width: 199px;
      `,
    },
  ];

  useEffect(() => {
    if (location.state?.refetch) {
      refetch();
      location.state = null;
    }
  }, [location]);

  return (
    <Wrapper data-testid='all-agencies__wrapper'>
      <ControlsWrapper>
        <Search search={handleSearch} />
        <Link to={'/create-agency'}>
          <CreateAgencyBtn>+ New Agency</CreateAgencyBtn>
        </Link>
      </ControlsWrapper>
      {<Count>{count} Agencies</Count>}
      <DataTable columns={columns} data={tableData} loading={isLoading} />
      {!isLoading && renderPagination(count)}
    </Wrapper>
  );
};

const AgencyName = styled.p`
  ${medium}
  color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.silver900};
`;

export default Kiosk;
