import { gql } from '@apollo/client';

import { UserSortedFields } from '../../../enums';
import { OrderingMap } from '../../../utils/base-interfaces';

import { Sorted } from './interfaces';

export const GET_AGENCY_USERS = gql`
  query getAgencyUsers($agencyId: Int!, $order: BaseOrder, $search: String, $pagination: PaginatedInput) {
    agencyUsers(agencyId: $agencyId, order: $order, search: $search, pagination: $pagination) {
      data {
        name
        id
        email
        createdAt
        role
      }
      count
    }
  }
`;

export const baseSorted: Sorted = {
  active: UserSortedFields.name,
  ordering: new Map([
    [UserSortedFields.name, true],
    [UserSortedFields.email, true],
    [UserSortedFields.createdAt, true],
    [UserSortedFields.role, true],
  ]) as OrderingMap<UserSortedFields, boolean>,
};

export default {
  GET_AGENCY_USERS,
  baseSorted,
};
