import React from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_AGENCY } from './settings';
import { KioskIcon } from 'components/icons/kiosk-icon';

import { BaseAgencyFormValues } from '../../utils/base-interfaces';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../components/form-elements/text-input/text-input';
import {
  FormWrapper,
  FormTitle,
  FormDescription,
  FormDisclaimer,
  FormNote,
  FormCardWrapper,
  FormCardTitle,
  FormControlsWrapper,
  SubmitBtn,
  CancelBtn,
  Form,
} from '../../styles/base';
import { errorHelper } from '../../utils/functions';

import { showToast } from '../../context/notification/notification.reducer';
import { useNotificationDispatch } from '../../context/notification/notification.context';
import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';

const CreateAgency: React.FC = () => {
  useBreadCrumbUpdate(['All Agencies', 'New Agency'], /* istanbul ignore next */ () => <KioskIcon />);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
    onSubmit: (values: BaseAgencyFormValues) => {
      createAgencyHandler(values);
    },
  });

  const dispatch = useNotificationDispatch();

  const [createAgency] = useMutation(CREATE_AGENCY);

  const createAgencyHandler = (variables: BaseAgencyFormValues) => {
    createAgency({ variables })
      .then(({ data }) => {
        dispatch(showToast(data.createAgency));
        navigate('/', { replace: true, state: { refetch: true } });
        console.log(navigate('/'));
      })
      .catch(err => {
        dispatch(showToast(err.message, 'error'));
      });
  };

  return (
    <FormWrapper data-testid='agency-create'>
      <FormDisclaimer>
        <FormTitle>+ New Agency</FormTitle>
        <FormDescription>Before you create this new agency, make sure all the information is correct!</FormDescription>

        <FormNote>
          *Note: All fields are <b>required</b> unless specified.
        </FormNote>
      </FormDisclaimer>

      <FormCardWrapper>
        <FormCardTitle>Agency’s Info</FormCardTitle>
        <hr />
        <Form onSubmit={formik.handleSubmit} data-testid='agency-form'>
          <TextInput
            label='Agency’s Name'
            testid='agency-name'
            {...formik.getFieldProps('name')}
            {...errorHelper(formik, 'name')}
            name='name'
            placeholder='Agency’s Name'
          />
          <TextInput
            label='Agency’s Owner Email'
            testid='agency-email'
            type='email'
            {...formik.getFieldProps('email')}
            {...errorHelper(formik, 'email')}
            name='email'
            placeholder='Agency’s Owner Email'
          />

          <FormControlsWrapper>
            <CancelBtn onClick={/* istanbul ignore next */ () => navigate('/', { replace: true })} data-testid='agency-create-cancel'>
              Cancel
            </CancelBtn>
            <SubmitBtn type='submit' disabled={!formik.isValid} data-testid='agency-create-submit'>
              Create Agency
            </SubmitBtn>
          </FormControlsWrapper>
        </Form>
      </FormCardWrapper>
    </FormWrapper>
  );
};

export default CreateAgency;
