import { ReactComponent as SmallLogoSVG } from 'assets/svg/sidebar-logo.svg';
import styled from 'styled-components';
import { ButtonXS } from 'styles/buttons';
import { mediumTwoXs, small } from 'styles/typography';

export const NewPasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mediumTwoXs};
  color: ${({ theme }) => theme.colors.silver400};
  button {
    background: none;
    border: none;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.mediumSlate};
    cursor: pointer;
  }
  p {
    margin: 0;
  }
  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 18px;
    height: 18px;
    border: solid 2px black;
    border-radius: 4px;
    margin-right: 9px;
    display: grid;
    place-content: center;
    cursor: pointer;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      left: 4px;
      width: 4px;
      height: 8px;
      border-right: solid 2px white;
      border-bottom: solid 2px white;
      transform: scale(0) rotate(0);
      transition: 120ms transform ease-in-out;
    }
    :checked {
      background-color: black;
      ::before {
        transform: scale(1) rotate(45deg);
      }
    }
  }
`;

export const SmallLogo = styled(SmallLogoSVG)`
  width: 74px;
  margin-bottom: 62px;
`;

export const Button = styled.button`
  ${ButtonXS};
  background: ${({ theme }) => theme.colors.mediumPink};
  color: ${({ theme }) => theme.colors.white};
  width: 91px;
  margin-top: 64px;
  :disabled {
    background: ${({ theme }) => theme.colors.silver400};
  }
`;

export const FormLabel = styled.p`
  ${small};
  color: ${({ theme }) => theme.colors.silver500};
  width: 100%;
  margin-bottom: 34px;
`;
