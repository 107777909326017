import { combineReducers } from 'redux';

import { authReducer } from './auth-reducer';
import { invitesModalReducer } from './invites-modal-reducer';
import { passwordValidationRulesReducer } from './password-validation-rules-reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  invitesModal: invitesModalReducer,
  passwordValidation: passwordValidationRulesReducer,
  // Add aditional reducers here as needed
});
