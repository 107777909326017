import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, useMutation, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwtDecode from 'jwt-decode';

import { CUser } from 'cognito.types';
import Layout from 'components/layout';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import AcceptInvite from 'routes/accept-invite';
import AddCompany from 'routes/add-company';
import Agency from 'routes/agency';
import AgencyTransfer from 'routes/agency-transfer';
import AllAgencies from 'routes/all-agencies';
import { closeModalAction } from 'state/reducers/invites-modal-reducer';
import Kiosk from 'routes/kiosk';
import Login from 'routes/login';
import Profile from 'routes/profile';
import { setTokenAction, setUserAction } from 'state/reducers/auth-reducer';
import { useAppDispatch, useAppSelector } from 'state/state-hooks';

import NotificationProvider from './context/notification/notification.provider';
import CreateAgency from './routes/create-agency';
import EditAgency from './routes/edit-agency';
import { signOutHandler } from 'utils/sign-out';
import ConfirmForgotPassword from './routes/confirm-forgot-password';
import ForgotPassword from './routes/forgot-password';
import { GET_CURRENT_USER } from 'utils/gql';
import { showToast } from 'context/notification/notification.reducer';
import CreateCompany from './routes/create-company';

const REFRESH_TOKEN = gql`
  mutation refreshIdToken($refreshToken: String!) {
    refreshIdToken(refreshToken: $refreshToken)
  }
`;

const App: React.FC = () => {
  const { auth, invitesModal } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: auth.idToken ? `Bearer ${auth.idToken}` : '',
    },
  }));

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  const [getCurrentUserDetails] = useMutation<{ getSignedInUserDetails: CUser }>(GET_CURRENT_USER, { client });

  const [refreshIdToken] = useMutation<{ refreshIdToken: string }>(REFRESH_TOKEN, { client });

  const getUser = async (): Promise<void> => {
    void getCurrentUserDetails()
      .then(res => {
        if (res.data?.getSignedInUserDetails) {
          dispatch(setUserAction(res.data.getSignedInUserDetails));
        }
      })
      .catch(err => {
        console.error(err);
        if (err instanceof Error) dispatch(showToast(err.message, 'error'));
        signOutHandler();
      });
  };

  const refreshTokens = async () => {
    refreshIdToken({ variables: { refreshToken: auth.refreshToken } })
      .then(res => {
        if (res.data?.refreshIdToken) dispatch(setTokenAction({ idToken: res.data.refreshIdToken, refreshToken: auth.refreshToken }));
      })
      .catch(err => {
        console.error(err);
        signOutHandler();
      });
  };

  useEffect(() => {
    if (auth.idToken && auth.refreshToken) {
      const { exp } = jwtDecode(auth.idToken) as { exp: number };
      const idTokenExpired = Math.floor(new Date().getTime() / 1000) > exp;
      if (idTokenExpired) {
        refreshTokens();
      } else {
        if (!auth.isSignedIn) {
          void getUser();
        }
      }
    }
    if (auth.refreshToken && !auth.idToken) {
      refreshTokens();
    }
    if (invitesModal.isOpen) {
      dispatch(closeModalAction());
    }
  }, [auth.idToken, auth.refreshToken, auth.isSignedIn]);

  const IndexComponent = auth.user.attributes.isKioskUser ? Kiosk : AllAgencies;

  return (
    // todo move NotificationProvider ToastContainer to redux
    <NotificationProvider>
      <ApolloProvider client={client}>
        {auth.isSignedIn ? (
          <Layout>
            <Routes>
              <Route index element={<IndexComponent />} />
              <Route path='profile' element={<Profile />} />
              <Route path='/create-agency' element={<CreateAgency />} />
              <Route path='/partner-form' element={<CreateCompany />} />
              <Route path='edit-agency/:agencyId' element={<EditAgency />} />
              <Route path='agency/:agencyId/*' element={<Agency />} />
              <Route path='/add-company' element={<AddCompany />} />
              <Route path='/accept-invite/:token' element={<AcceptInvite />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </Layout>
        ) : (
          <Routes>
            <Route index element={<Login />} />
            <Route path='/agency-transfer' element={<AgencyTransfer />} />
            <Route path='forgot_password' element={<ForgotPassword />} />
            <Route path='confirm_forgot_password/:email' element={<ConfirmForgotPassword />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        )}
      </ApolloProvider>
      <ToastContainer theme='dark' />
    </NotificationProvider>
  );
};

export default App;
