import { ReactComponent as LogoSVG } from 'assets/svg/full-logo.svg';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonXS } from 'styles/buttons';
import { twoXs, xs } from 'styles/typography';

export const Wrapper = styled.main`
  display: flex;
  min-height: 100vh;
`;

export const FormWrapper = styled.div`
  width: 599px;
  padding: 0 0 0 84px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
`;

export const Image = styled.img`
  height: 100vh;
  flex: 1;
  object-fit: cover;
`;

export const Form = styled.form`
  width: 299px;
`;

export const Logo = styled(LogoSVG)`
  margin-bottom: 70px;
`;

export const ErrorMessage = styled.p`
  ${xs}
  color: ${({ theme }) => theme.colors.mediumStrawberry};
`;

export const Button = styled.button`
  ${ButtonXS}
  background: ${({ theme }) => theme.colors.mediumPink};
  color: ${({ theme }) => theme.colors.white};
  width: 153px;
`;

export const LoginNavLink = styled(NavLink)`
  ${twoXs}
  text-decoration: underline;
  ${({ theme }) => theme.colors.silver400};
`;

export const ResetPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
