import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { setTokenAction } from 'state/reducers/auth-reducer';
import { useAppDispatch } from 'state/state-hooks';

const AgencyTransfer: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const refreshToken = searchParams.get('refreshToken');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof refreshToken === 'string') {
      dispatch(setTokenAction({ idToken: '', refreshToken }));
    } else {
      navigate('/', { replace: true });
    }
  }, [refreshToken]);

  return <></>;
};

export default AgencyTransfer;
