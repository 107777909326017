import styled from 'styled-components';

const TermsAndConditions = () => (
  <TextContent data-testid='terms-and-conditions__wrapper'>
    <p>
      This web page represents our Terms and Conditions of Sale ("Agreement") regarding our website, www.socialswell.co or www.swell.reviews
      or www.swellcx.com as well as any of its related websites, applications, dashboards or platforms (individually and collectively, the
      "Website"). It was last posted January 2017. The terms, "we" and "our" as used in this Agreement refer to Swell and/or SocialSwell,
      owned and operated by SocialSwell, Inc.
    </p>
    <p>
      YOU ACKNOWLEDGE AND AGREE THAT BY ACCESSING OR USING THE SWELL SITE OR SERVICES, OR POSTING OR ACCESSING ANY CONTENT ON THE SITE OR
      THROUGH THE SERVICES, YOU ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO
      THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SITE, SERVICES OR SWELL CONTENT.
    </p>
    <p>
      We may amend this Agreement at any time by posting the amended terms on our Website. We may or may not post notices on the homepage of
      our Website when such changes occur.We refer to this Agreement, our Privacy Policy accessible at https://swellcx.com/privacy-policy,
      and any other terms, rules, or guidelines on our Website collectively as our "Legal Terms." You explicitly and implicitly agree to be
      bound by our Legal Terms each time you access our Website. If you do not wish to be so bound, please do not use or access our Website.
    </p>
    <h2>Limited License</h2>
    <p>
      Swell grants you a non-exclusive, non-transferable, revocable license to access and use our Website in order for you to make purchases
      of electronic documents and related services through our Website, strictly in accordance with our Legal Terms.
    </p>
    <h2> Services, Terms and Fees</h2>
    <p>
      Swell offers various services including software to assist in the management of online reviews and reputation, social posting, and
      customer feedback. Swell offers both monthly and annual Service agreements. The initial term for monthly Service agreements is at
      least 30 days, unless otherwise specified and agreed upon by both parties. The initial term for annual Service agreements provided by
      Swell is at least twelve (12) months, unless otherwise specified and agreed upon by both parties. The total amount of the Purchase
      Price shall be payable in full according to the payment due date stated at Checkout. Any portion of the Purchase Price unpaid past
      thirty (30) days shall be considered overdue. All amounts past due are subject to a late charge of the lesser of one and one-half
      percent (1 1/2%) per month (being eighteen percent (18%) per annum) or the highest lawful rate. You understand and accept that, unless
      otherwise expressly stated on the applicable order, our services are subscription services that operate on an auto-renewal basis such
      that your credit card, debit card, electronic payment, or other method of payment ("Accounts") will be assessed the specified Fees at
      regular intervals based on your subscription program (annually, or monthly). The Fees will be paid in advance.
    </p>
    <p>
      In addition, Swell shall have the right to pursue any remedies available at law or as provided herein and shall be entitled to
      reimbursement from you for Swell's costs of collection, including attorney fees, legal fees and costs and disbursements.
    </p>
    <h2>TCPA</h2>
    <p>
      Customer is responsible to comply with any local and federal laws outlined by the Telephone Consumer Protection Act (“TCPA” 47 U.S.C.
      § 227). By accepting Company’s Terms and Conditions, Customer is aware of and responsible for obtaining explicit consent from any and
      all third parties who are sent or receive an SMS/MMS and/or email communications through Company’s services on Customer’s behalf. Each
      party is liable for, and shall indemnify, defend and hold harmless the other party from and against any and all damages, liabilities,
      judgements, fines, costs and expenses incurred by the non-breaching party arising from claims related to TCPA
    </p>
    <h2>Copyrights and Trademarks</h2>
    <p>
      Unless otherwise noted, all materials including without limitation, logos, brand names, images, designs, photographs, video clips and
      written and other materials that appear as part of our Website are copyrights, trademarks, service marks, trade dress and/or other
      intellectual property whether registered or unregistered ("Intellectual Property") owned, controlled or licensed by Swell. Our Website
      as a whole is protected by copyright and trade dress. Nothing on our Website should be construed as granting, by implication, estoppel
      or otherwise, any license or right to use any Intellectual Property displayed or used on our Website, without the prior written
      permission of the Intellectual Property owner. Swell aggressively enforces its intellectual property rights to the fullest extent of
      the law. The names and logos of Swell, may not be used in any way, including in advertising or publicity pertaining to distribution of
      materials on our Website, without prior, written permission from Swell. Swell prohibits use of any logo of Swell or any of its
      affiliates as part of a link to or from any Website unless Swell approves such link in advance and in writing. Fair use of Swell's
      Intellectual Property requires proper acknowledgment. Other product and company names mentioned in our Website may be the Intellectual
      Property of their respective owners.
    </p>
    <h2>Links to Third-Party Websites</h2>
    <p>
      Our Website may contain links to Websites owned or operated by parties other than Swell. Such links are provided for your reference
      only. Swell does not monitor or control outside Websites and is not responsible for their content. Swell's inclusion of links to an
      outside Website does not imply any endorsement of the material on our Website or, unless expressly disclosed otherwise, any
      sponsorship, affiliation or association with its owner, operator or sponsor, nor does Swell's inclusion of the links imply that Swell
      is authorized to use any trade name, trademark, logo, legal or official seal, or copyrighted symbol that may be reflected in the
      linked Website.
    </p>
    <h2>Content Disclaimer</h2>
    <p>
      Postings on our Website are made at such times as Swell determines in its discretion. You should not assume that the information
      contained on our Website has been updated or otherwise contains current information. Swell does not review past postings to determine
      whether they remain accurate and information contained in such postings may have been superseded. THE INFORMATION AND MATERIALS IN OUR
      WEBSITE ARE PROVIDED FOR YOUR REVIEW IN ACCORDANCE WITH THE NOTICES, TERMS AND CONDITIONS SET FORTH HEREIN. THESE MATERIALS ARE NOT
      GUARANTEED OR REPRESENTED TO BE COMPLETE, CORRECT OR UP TO DATE. THESE MATERIALS MAY BE CHANGED FROM TIME TO TIME WITHOUT NOTICE.
    </p>
    <h2>Contracts, Attorneys, and Legal Advice Disclaimer</h2>
    <p>
      You understand that we offer legal contracts ("Contracts"), as created by attorneys or paralegal for sale through our Website. We also
      offer you the ability to purchase reviews of such Contracts by attorneys or paralegals who have registered with our Website. However,
      you acknowledge that we are not attorneys or paralegals nor do we offer legal advice. You acknowledge that we do not endorse any
      specific attorney or paralegal or any Contracts as being better than another. We do not otherwise guarantee the legal accuracy or
      applicability of any Contracts for your legal needs. You will at all times look to any attorney or paralegal that you select for
      services as to any legal claims related to such services.
    </p>
    <h2>No Warranties; Exclusion of Liability; Indemnification</h2>
    <p>
      OUR WEBSITE IS OPERATED BY SWELL ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE
      FULLEST EXTENT PERMITTED BY LAW, SWELL SPECIFICALLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING ALL IMPLIED
      WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT FOR OUR WEBSITE AND ANY
      CONTRACTS AND SERVICES YOU PURCHASE THROUGH IT. SWELL SHALL NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN
      THE CONTENT OF OUR WEBSITE, FOR CONTRACTS OR SERVICES SOLD THROUGH OUR WEBSITE, FOR YOUR ACTION OR INACTION IN CONNECTION WITH OUR
      WEBSITE OR FOR ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGE YOU MAY INCUR IN CONNECTION WITH OUR WEBSITE. YOUR USE OF OUR
      WEBSITE AND ANY CONTRACTS OR SERVICES ARE AT YOUR OWN RISK. IN NO EVENT SHALL EITHER SWELL OR THEIR AGENTS BE LIABLE FOR ANY DIRECT,
      INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OUR WEBSITE,
      CONTRACTS AND SERVICES PURCHASED THROUGH OUR WEBSITE, THE DELAY OR INABILITY TO USE OUR WEBSITE OR OTHERWISE ARISING IN CONNECTION
      WITH OUR WEBSITE, CONTRACTS OR RELATED SERVICES, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF
      THE POSSIBILITY OF ANY SUCH DAMAGES. IN NO EVENT SHALL SWELL'S LIABILITY FOR ANY DAMAGE CLAIM EXCEED THE AMOUNT PAID BY YOU TO SWELL
      FOR THE TRANSACTION GIVING RISE TO SUCH DAMAGE CLAIM.
    </p>
    <p>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION MAY NOT
      APPLY TO YOU.
    </p>
    <p>
      WITHOUT LIMITING THE FOREGOING, SWELL DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION ON THE WEBITE IS ACCURATE, COMPLETE,
      RELIABLE, USEFUL, TIMELY OR CURRENT OR THAT OUR WEBSITE WILL OPERATE WITHOUT INTERRUPTION OR ERROR.
    </p>
    <p>
      YOU AGREE THAT ALL TIMES, YOU WILL LOOK TO ATTORNEYS FROM WHOM YOU PURCHASE SERVICES FOR ANY CLAIMS OF ANY NATURE, INCLUDING LOSS,
      DAMAGE, OR WARRANTY.
    </p>
    <p>
      SWELL AND THEIR RESPECTIVE AFFILIATES MAKE NO REPRESENTATION OR GUARANTEES ABOUT ANY CONTRACTS AND SERVICES OFFERED THROUGH OUR
      WEBSITE.SWELL MAKES NO REPRESENTATION THAT CONTENT PROVIDED ON OUR WEBSITE, CONTRACTS, OR RELATED SERVICES ARE APPLICABLE OR
      APPROPRIATE FOR USE IN ALL JURISDICTIONS.
    </p>
    <h2>Indemnification</h2>
    <p>
      You agree to defend, indemnify and hold Swell harmless from and against any and all claims, damages, costs and expenses, including
      attorneys' fees, arising from or related to your use of our Website or any Contracts or Services you purchase through it.
    </p>
    <hr />
    <p>END-USER LICENSE AGREEMENT</p>
    <h2>Software User License Agreement</h2>
    <p>
      This Software User Agreement ("Agreement") is made and entered into as of the Effective Date by and between SocialSwell, Inc, a
      Delaware Corporation ("Swell"), and the party accessing the Licensed Deliverables or any Entity on behalf of which the Licensed
      Deliverables are accessed. ("User", "Customers" or "Licensee"). Swell and User are individually referred to as a "Party" and
      collectively referred to as the "Parties." "Effective Date" shall mean the earlier on which Licensee accesses the Licensed
      Deliverables (as defined below)
    </p>
    <h2>Recitals</h2>
    <p>
      WHEREAS, Swell is has developed software that is intended to assist business's with online reputation management, social media
      managementent and location management. The software package referred to as Swell (the "Software") is described in greater detail in
      the 'Definitions' section herein; and
    </p>
    <p>
      WHEREAS, User desires to access and utilize such Software in conjunction with its business; WHEREAS, Swell and User believe it is in
      their mutual interest and desire to enter into an agreement whereby User would use Swell's Software pursuant to the terms and
      conditions hereinafter provided.
    </p>
    <p>NOW, THEREFORE, in consideration of the premises and the mutual covenants of this Agreement, the Parties hereto agree as follows:</p>
    <h2>Terms Definitions</h2>
    <p>
      'HIPAA' means the Health Information Technology for Economic and Clinical Health Act of 2009 (the "HITECH Act"), the Administrative
      Simplification section of the Health Insurance Portability and Accountability Act of 1996, as codified at 42 U.S.C. §1320d through
      d-8, as amended from time to time ("HIPAA"), and the requirements of any regulations promulgated under either the HITECH Act or HIPAA,
      including, without limitation, the federal privacy regulations as contained in 45 C.F.R. Parts 160 and 164, the federal security
      standards as contained in 45 C.F.R. Parts 160, 162 and 164, and the federal standards for electronic transactions contained in 45
      C.F.R. Parts 160 and 162, all as may be amended from time to time.-
    </p>
    <p>
      'Software' means SocialSwell's software commonly referred to as Swell, which is software that assists businesses in managing their
      online reviews and reputation, manage social postings, and gather customer feedback. The term 'Software' includes all derivative work,
      information and materials related to the Software
    </p>
    <p>
      'Software Deliverables' means the Software (as that term is defined herein), any modifications or alternations thereto, all
      Software-related derivative work, information, materials, and training, and the Software Platform (as that term is defined herein).
    </p>
    <p>
      'Software Platform' shall mean the web-based portal and platform that hosts the Software and all Customer-specific data. The Customer
      is only able to utilize the Software through this Software Platform.
    </p>
    <h2>Authorization To Use Software</h2>
    <p>
      ‍Swell hereby grants to User, for the term of this Agreement, a nonexclusive, nonassignable right to use the Software
      ("Authorization") in connection with its business, by accessing the Software through the web-based Software Platform. The Customer
      shall have no right to utilize or access the Software in any other manner, other than by accessing the Software through the web-based
      Software Platform.
    </p>
    <h2>Restrictions On Use</h2>
    <p>
      Users. Individuals accessing the Software must be members of the Customer's workforce. Customer agrees that it will not allow the
      sharing of user names or login credentials to access the Software Platform or Software. Customer shall be solely responsible for
      ensuring that anyone it gives access to the Software Platform or Software, is a member of its workforce, and authorized under its
      Company policies and federal and state law, to access the information contained in the Software
    </p>
    <p>
      Platform and Software. Transfers, Assigns, and Authorization to Others Not Allowed. The Authorization is not assignable and not
      transferrable. User may not rent, loan, license, market, or sell the Authorization, the Software, the Software Deliverables or copies
      thereof, in whole or in part, to any party.
    </p>
    <p>
      Internal Business Use. The Authorization allows User to use the Software Deliverables for User's own use and internal business
      purposes. User agrees to restrict the use of the Software Deliverables to its employees and authorized agents. Should User become
      aware of any unauthorized use or access of the Software Deliverables, User shall take all steps reasonably necessary to terminate the
      unauthorized use and agrees to immediately notify Swell. User also agrees to cooperate and assist Swell in taking measures and actions
      to prevent or terminate unauthorized use or access of the Software Deliverables by third parties.
    </p>
    <p>
      Login Credentials. User agrees that it will be solely responsible for authorizing and providing login credentials for any and all
      agents of User; which login credentials will allow for the access to the Software Platform and Software. Customer shall be solely
      responsible for ensuring that anyone it gives access to the Software Platform or Software, is a member of its workforce, and
      authorized under its Company policies and federal and state law, to access the information contained in the Software Platform and
      Software. If applicable, User expressly represents and warrants that it will fully comply with HIPAA, including ensuring that all
      individuals it allows to access the Software and the Software Platform are allowed under HIPAA and under relevant state privacy laws.
      Swell takes no responsibility for any liability associated with Customer's acts or omissions related to whom Customer allows to access
      the Software and the Software Platform, and Customer shall indemnify and defend Swell against all claims or administrative
      investigations or actions which arise from User's use, authorization to allow others to use, or failure to prevent unauthorized use,
      of the Software or the Software Platform. Additionally, Customer agrees that it will not allow the sharing of user names or login
      credentials to access the Software Platform or Software. Copies. User is prohibited from making any copies, archival or otherwise, of
      the Software.
    </p>
    <p>
      Strict Construction. User is further prohibited from accessing or using the Software in any manner other than as described herein.
    </p>
    <p>
      Duty to Notify Employees. Company hereby agrees (i) to notify its employees and agents who may have access to the Software or Software
      Deliverables of the restrictions contained in this Agreement and (ii) to ensure their compliance with such restrictions.
    </p>
    <h2>Confidentiality</h2>
    <p>
      ‍All of the Software Deliverables, including but not limited to, the Software and the Software Platform, shall be considered
      "Confidential Information" under this Agreement, and User shall comply with all related restrictions. User recognizes that the
      Software is the proprietary and confidential property of Swell. Accordingly, User shall not, without the prior express written consent
      of Swell, during the term of this Agreement and for five years thereafter, disclose or reveal to any third party or utilize for its
      own benefit other than pursuant to this Agreement, any information related to the Software Deliverables, including the Software,
      provided that such information was not previously known to User or to the general public. This provision prevents User (or any of its
      agents) from reverse engineering the Software or attempting to reverse engineer the Software; it also precludes User, for User's or a
      third party's gain, utilizing information related to the Software Deliverables. User agrees to take all reasonable precautions to
      preserve the confidentiality of Swell's Software and shall assume responsibility that its employees, agents and related parties will
      similarly preserve this information against third parties. The provisions of this clause shall survive termination of this Agreement.
    </p>
    <h2>Copyright And Title</h2>
    <p>
      The Software Deliverables and any copy thereof, in whole or in part, and all copyrights, trade secrets and other proprietary rights
      therein, including any derivative work, are the sole property of Swell. The Software Deliverables are protected by certain United
      States and international copyright laws and trademark laws. The Authorization confers no title of ownership in the Software
      Deliverables to User, nor does the Authorization constitute any transfer or sale of any of Swell's rights to the Software
      Deliverables. User agrees to treat the Software Deliverables with at least the same standard of care as it treats any other material
      copyrighted and/or trademarked by a third party, in no case less than a reasonable standard of care.
    </p>
    <h2>Training, And Acceptance</h2>
    <p>Training. Swell shall provide training to the User regarding the use of the Software. This shall be provided via telephone.</p>
    <p>
      In the event that User fails to notify Swell of any difficulties or problems with the Software within ten (10) days after the Customer
      begins to utilize the Software, User shall be deemed to have accepted the Software. Prior to acceptance of such Software, Swell shall
      have the right to repair or replace the Software at its discretion. Upon acceptance of such Software, Swell shall be under no
      obligation to repair or replace such Software except as provided for in the Warranties provisions of this Agreement.
    </p>
    <h2>Warranties</h2>
    <p>
      Swell represents that the Software will be available from most devices that have a web-browser, including personal computers and
      tablets; however, the Software will not be compatible with mobile phones of any kind.
    </p>
    <p>Swell represents and warrants that it has no actual knowledge that the Software infringes any valid rights of any third party.</p>
    <p>
      Swell warrants that the Software will perform in accordance with the specifications provided by Swell to User. THE WARRANTY PROVIDED
      FOR HEREIN IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, THAT MAY ARISE EITHER BY AGREEMENT BETWEEN THE PARTIES OR BY
      OPERATION OF LAW, INCLUDING THE WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
    </p>
    <p>
      In the event of a claim by User under this warranty, Swell shall have the option to either repair or replace the Software. In the
      event that Swell fails to repair or replace the Software within a reasonable period, User's sole recourse shall be to terminate the
      Agreement. In the case of such termination, Swell's financial remedy shall be limited to the actual fees paid to Swell by User since
      providing notice of the claim to Swell. In no event shall Swell be liable for any incidental, consequential, or punitive damages as a
      result of its performance or breach of this Agreement.
    </p>
    <h2>Improvements</h2>
    <p>
      ‍Any improvements or modifications made by Swell to the Software or the Software Deliverables shall be promptly provided to User and
      shall be automatically included in this Agreement. User is precluded from making or attempting to make any modifications, changes or
      improvements ("Software Changes") to the Software or the Software Deliverables. Any Software Changes shall remain the intellectual
      property of the Swell, and shall not affect Swell's legal rights and interest in the Software Deliverables.
    </p>
    <h2>Post-Termination Rights</h2>
    <p>
      ‍Upon the expiration or termination of the Master Agreement, all rights granted to User shall forthwith terminate. Upon such
      expiration or termination, Swell shall terminate User's access to the Software through the Software Platform and User shall
      discontinue all use of the Software and the Software Deliverables.
    </p>
    <h2>Telephone Consumer Protection Act</h2>
    <p>
      User acknowledges and agrees that the access and use of the Licensed Deliverables are subject to local, state and federal laws and
      regulations (“Applicable Law”), including the Telephone Consumer Protection Act (47 U.S.C. § 227) and the Telemarketing Sales Rule
      (collectively, “TCPA”), as well as export control laws and regulations of the United States and other jurisdictions. User agrees to
      fully comply with all Applicable Law, including TCPA, and shall indemnify, defend and hold Swell harmless from and against all claims,
      damages and liability arising from a breach of the foregoing obligations.
    </p>
    <p>Copyright Social Swell Inc.</p>
  </TextContent>
);

export default TermsAndConditions;

const TextContent = styled.article`
  height: 426px;
  overflow-y: auto;
  border-radius: 8px;
  margin: 0 0;
  padding: 0 12px;
  font-size: 14px;
  line-height: 22px;

  h1 {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 1em;
  }
  h2 {
    font-weight: bold;
    margin-bottom: 1em;
  }
  p {
    margin-bottom: 1em;
  }
  hr {
    width: 100px;
    height: 1px;
    background: black;
    margin-bottom: 1em;
  }
`;
