import { gql, useMutation } from '@apollo/client';
import { CUser } from 'cognito.types';
import { showToast } from 'context/notification/notification.reducer';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import { setUserAction } from 'state/reducers/auth-reducer';
import { useAppDispatch } from 'state/state-hooks';
import styled from 'styled-components';
import { boldSmall, mediumSmall, xs } from 'styles/typography';
import { GET_CURRENT_USER } from 'utils/gql';

export const UPDATE_USER = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user)
  }
`;

interface Props {
  user: CUser;
}

const EditProfile: React.FC<Props> = ({ user }) => {
  const [updateUser] = useMutation(UPDATE_USER);
  const [getCurrentUserDetails] = useMutation<{ getSignedInUserDetails: CUser }>(GET_CURRENT_USER);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const { given_name, family_name, email, phone_number } = user.attributes;
  // eslint-disable-next-line
  const [fName, setFName] = useState(given_name as string);
  // eslint-disable-next-line
  const [lName, setLName] = useState(family_name as string);
  // eslint-disable-next-line
  const [phone, setPhone] = useState(user.attributes.phone_number as string);
  const somethingIsDifferent = fName !== user.attributes.given_name || lName !== family_name || phone !== phone_number; // eslint-disable-next-line

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (somethingIsDifferent) {
      const newUser = {
        ...user,
        attributes: {
          ...user.attributes,
          // eslint-disable-next-line
          given_name: fName,
          // eslint-disable-next-line
          family_name: lName,
          // eslint-disable-next-line
          phone_number: phone,
        },
      } as CUser;

      updateUser({
        variables: {
          user: {
            email: email,
            name: `${newUser.attributes.given_name} ${newUser.attributes.family_name}`,
            phoneNumber: newUser.attributes.phone_number,
            userId: user.attributes.userId,
          },
        },
      }).then(() => {
        dispatch(showToast(`Changes saved`));
        void getCurrentUserDetails()
          .then(res => {
            if (res.data?.getSignedInUserDetails) dispatch(setUserAction(res.data.getSignedInUserDetails));
          })
          .catch(err => console.error(err));
      });
    }
  };

  return (
    <Wrapper data-testid='edit-profile__wrapper'>
      <Title>Edit Profile</Title>
      <Hr />
      <form onSubmit={handleSubmit} data-testid='edit-profile__form'>
        <Inputs>
          <Label>
            First Name
            <Input value={fName} onChange={e => setFName(e.target.value)} required data-testid='edit-profile__f-name-input' />
          </Label>
          <Label>
            Last Name
            <Input value={lName} onChange={e => setLName(e.target.value)} required data-testid='edit-profile__l-name-input' />
          </Label>
          <Label>
            Email Address
            <Input value={email} type='email' disabled={true} required data-testid='edit-profile__email-input' />
          </Label>
          <Label>
            Phone Number
            <Input value={phone ? phone : ''} onChange={e => setPhone(e.target.value)} required data-testid='edit-profile__phone-input' />
          </Label>
        </Inputs>
        <Buttons>
          <Cancel onClick={() => navigate(-1)} data-testid='edit-profile__cancel'>
            Cancel
          </Cancel>
          <Save disabled={!somethingIsDifferent} type='submit' data-testid='edit-profile__submit'>
            Save
          </Save>
        </Buttons>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 600px;
  max-width: 892px;
  flex: 1;
  padding: 24px;
`;
const Title = styled.p`
  ${boldSmall}
`;
const Hr = styled.hr`
  margin: 15px 0;
  border: solid 1px ${({ theme }) => theme.colors.black};
`;
const Inputs = styled.div`
  display: grid;
  grid-template-columns: 48.58% 48.58%;
  grid-column-gap: 2.84%;
  grid-row-gap: 24px;
`;
const Label = styled.label`
  ${xs};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.silver200};
  margin-top: 6px;
  height: 38px;
  border-radius: 8px;
  padding: 6px 12px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 290px;
`;
const Button = styled.button`
  ${mediumSmall};
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 6px 10px;
  border-radius: 6px;
`;
const Cancel = styled(Button)`
  color: ${({ theme }) => theme.colors.silver500};
  border: 1px solid ${({ theme }) => theme.colors.silver300};
  margin-right: 20px;
`;
const Save = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.mediumPink};
  :disabled {
    background: ${({ theme }) => theme.colors.silver400};
    cursor: default;
  }
`;
export default EditProfile;
