import { createAction, createReducer } from '@reduxjs/toolkit';
import { CUser } from 'cognito.types';

export const setTokenAction = createAction<{ idToken: string; refreshToken: string }>('SET_TOKEN');
export const signOutAction = createAction('LOG_OUT');
export const setUserAction = createAction<CUser>('SET_DB_INFO');

export interface AuthenticationState {
  isSignedIn: boolean;
  user: CUser;
  idToken: string;
  refreshToken: string;
  dbInfoFetched: boolean;
}

export const initialState: AuthenticationState = {
  isSignedIn: false,
  dbInfoFetched: false,
  user: {
    attributes: {},
  } as unknown as CUser,
  idToken: undefined as unknown as string,
  refreshToken: undefined as unknown as string,
};

export const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(setTokenAction, (state, action) => {
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    })
    .addCase(setUserAction, (state, action) => {
      state.user = {
        ...state.user,
        attributes: {
          ...state.user.attributes,
          ...action.payload,
        },
      };
      state.isSignedIn = true;
    })
    .addCase(signOutAction, state => {
      state.isSignedIn = false;
      state.user = initialState.user;
      state.idToken = initialState.idToken;
      state.refreshToken = initialState.refreshToken;
    });
});
