import { useAppSelector } from 'state/state-hooks';
import { ButtonXsWhite } from 'styles/buttons';
import { ReactComponent as GtIcon } from '../../../assets/svg/gt-icon.svg';

interface Props {
  companyId: number;
}

const Launch: React.FC<Props> = ({ companyId }) => {
  const { idToken, refreshToken } = useAppSelector(/* istanbul ignore next*/ state => state.auth);
  return (
    <a
      href={`${
        process.env.REACT_APP_SWELL_WEB_URL as string
      }/agency-transfer/?company=${companyId}&idToken=${idToken}&refreshToken=${refreshToken}`}
      data-testid='companies__launch-button'
    >
      <ButtonXsWhite>
        Launch <GtIcon />
      </ButtonXsWhite>
    </a>
  );
};

export default Launch;
