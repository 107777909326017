import { congitoChallenges } from 'app-constants';
import loginImage from 'assets/images/login.jpg';
import { Auth } from 'aws-amplify';
import { CUser } from 'cognito.types';
import TextInput from 'components/form-elements/text-input/text-input';
import { useEffect, useState } from 'react';
import { setTokenAction } from 'state/reducers/auth-reducer';
import { useAppDispatch } from 'state/state-hooks';
import ConfirmName from './components/confirm-name';

import NewPassword from './components/new-password';
import { Button, Form, FormWrapper, Image, Logo, Wrapper, ErrorMessage, ForgotPasswordNavLink } from './styles';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState<CUser>();
  const [challenge, setChallenge] = useState<string>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      if (user.challengeName !== undefined) {
        setChallenge(user.challengeName);
      } else if (user.attributes.family_name && user.attributes.given_name) {
        dispatch(
          setTokenAction({ idToken: user.signInUserSession.idToken.jwtToken, refreshToken: user.signInUserSession.refreshToken.token })
        );
      } else {
        setChallenge(congitoChallenges.MISSING_NAME_ATTRIBUTE);
      }
    }
  }, [user]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const u = (await Auth.signIn(email, password, { portal: 'agency' })) as CUser;
      setUser(u);
    } catch (err) {
      if (err instanceof Error) {
        setLoading(false);
        setError(err.message);
      }
    }
  };

  const renderFields = () => {
    switch (challenge) {
      case undefined:
        return (
          <>
            <FormWrapper data-testid='login__form-wrapper'>
              <Form onSubmit={onSubmit}>
                <Logo display='block' />
                <ErrorMessage>{error}</ErrorMessage>
                <TextInput
                  testid='login__email-input'
                  label='Email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder='Email'
                  errorText='Invalid Email'
                  required
                  showError={false}
                  width='100%'
                  type='email'
                />
                <br />
                <TextInput
                  label='Password'
                  testid='login__password-input'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder='Password'
                  errorText='Invalid'
                  required
                  showError={false}
                  width='100%'
                  type='password'
                />
                <ForgotPasswordNavLink to='forgot_password'>
                  <p>Forgot Password?</p>
                </ForgotPasswordNavLink>
                <Button type='submit' data-testid='login__submit-button'>
                  {loading ? 'Loading...' : 'Login'}
                </Button>
              </Form>
            </FormWrapper>
            <Image src={loginImage} alt='' />
          </>
        );
      case congitoChallenges.NEW_PASSWORD_REQUIRED:
        return <NewPassword user={user || /* istanbul ignore next */ ({} as CUser)} />;
      case congitoChallenges.MISSING_NAME_ATTRIBUTE:
        return (
          <ConfirmName
            user={user || /* istanbul ignore next */ ({ attributes: { family_name: 'test', email: 'nstone@swellcx.com' } } as CUser)}
          />
        );
      /* istanbul ignore next */ default:
        /* istanbul ignore next */ break;
    }
  };

  return <Wrapper data-testid='login__wrapper'>{renderFields()}</Wrapper>;
};

export default Login;
