import { SortDirections } from 'enums';
import styled from 'styled-components';
import { twoXs } from 'styles/typography';

export const Header = styled.div<{ isSortable?: boolean; isSorting?: boolean; direction?: SortDirections }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isSortable }) =>
    isSortable &&
    `
    ::after {
      display: block;
      content: '';
      width: 0; 
      height: 0; 
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      margin-left: 12px;
      transition: 150ms;
    }
  `}
  ${twoXs}
  ${({ isSorting, direction }) =>
    isSorting &&
    `
    ::after {
      ${
        direction === SortDirections.ASC &&
        `
       transform: rotate(180deg);
      `
      }
    }
  `}
`;

export const ActionsHeader = styled(Header)`
  justify-content: flex-end;
  padding-right: 12px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 12px;
`;

export const Count = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.silver500};
`;
