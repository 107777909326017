import { HeaderWrapper, HeaderText } from './styles';

interface Props {
  title: string;
}

const SectionHeader: React.FC<Props> = ({ title }) => (
  <HeaderWrapper data-testid='section-header__header-wrapper'>
    <HeaderText data-testid='section-header__header-text'>{title}</HeaderText>
  </HeaderWrapper>
);

export default SectionHeader;
