import { ButtonXsPink } from 'components/buttons';
import styled from 'styled-components';

export const ElipsesContainer = styled.button`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #b7b7b7;
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

export const InviteUsersBtn = styled(ButtonXsPink)`
  min-width: 128px;
`;

export const UtilitiesRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
