import { SortDirections } from 'enums';
import { useState } from 'react';

export const tableSortDefaults = {
  currentSortedColumn: 'id',
  sortDirection: SortDirections.ASC,
};
export const useTableSort = () => {
  const [currentSortedColumn, setCurrentSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState(SortDirections.ASC);

  const handleSort = (columnName: string) => {
    if (currentSortedColumn === columnName) {
      const newDirection = sortDirection === SortDirections.ASC ? SortDirections.DESC : SortDirections.ASC;
      setSortDirection(newDirection);
    } else {
      setSortDirection(SortDirections.ASC);
      setCurrentSortedColumn(columnName);
    }
  };

  return {
    currentSortedColumn,
    sortDirection,
    handleSort,
  };
};
