import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../state/state-hooks';

import { KioskIcon, LinkWrapper } from './styles';

const SidebarLinks: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useAppSelector(state => state.auth);
  const links = [
    {
      name: 'kiosk',
      active: pathname === '/',
      renderCondition: user.attributes.isKioskUser,
      linkTo: '/',
      icon: <KioskIcon />,
    },
  ];

  return (
    <>
      {links.map(
        link =>
          link.renderCondition && (
            <Link key={link.name} to={link.linkTo}>
              <LinkWrapper active={link.active}>
                {link.icon}
                {link.name}
              </LinkWrapper>
            </Link>
          )
      )}
    </>
  );
};

export default SidebarLinks;
