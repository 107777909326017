import { useReducer } from 'react';

import { NotificationStateContext, NotificationDispatchContext } from './notification.context';
import { initialNotificationState, notificationReducer } from './notification.reducer';

const NotificationProvider: React.FC = ({ children }) => {
  const [notificationState, notificationDispatch] = useReducer(notificationReducer, initialNotificationState);
  return (
    <NotificationStateContext.Provider value={notificationState}>
      <NotificationDispatchContext.Provider value={notificationDispatch}>{children}</NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export default NotificationProvider;
