import styled from 'styled-components';

import { fadeIn } from '../../styles/animations';
import theme from '../../styles/theme';
import { small } from '../../styles/typography';

export const RoleWrapper = styled.div`
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ArrowContainer = styled.div<{ showChangeRole?: boolean }>`
  margin-left: 5px;
  position: relative;

  .data-item__change-role-arrow {
    ${({ showChangeRole }) => showChangeRole && `transform: rotate(180deg)`};
  }
  .data-item__pointer-arrow {
    position: absolute;
    top: 17px;
    left: -1px;
  }
`;

export const ChangeRoleContainer = styled.div`
  width: 109px;
  height: 84px;
  padding: 24px 16px;
  background-color: ${theme.colors.white};
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  left: -80px;
  top: 25px;
  animation: ${fadeIn} 300ms linear;
`;

export const ChangeRoleItem = styled.p`
  ${small};
  margin: 0;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;
