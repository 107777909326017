import { ReactComponent as AlertSVG } from 'assets/svg/alert.svg';
import styled from 'styled-components';

import { small, twoXs, xs } from '../../../styles/typography';

export const Wrapper = styled.label<{ width?: string; prependIcon?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${xs};
  width: ${({ width }) => (width ? width : '100%')};
  align-items: center;
  ${({ prependIcon }) =>
    prependIcon &&
    `
    position: relative;
`}
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Alert = styled(AlertSVG)`
  position: absolute;
  top: calc(50% - 8px);
  right: 12px;
`;

export const Input = styled.input<{ showError?: boolean; prependIcon?: boolean }>`
  ${small};
  display: block;
  margin: 6px 0;
  height: 38px;
  width: 100%;
  padding: 8px 12px 8px ${({ prependIcon }) => (prependIcon ? '25px' : '12px')};
  border-radius: 8px;
  border: solid 1px ${({ theme, showError }) => (showError ? theme.colors.mediumStrawberry : theme.colors.silver200)};
  ::placeholder {
    color: ${({ theme }) => theme.colors.silver400};
  }
  :disabled {
    background: ${({ theme }) => theme.colors.silver200};
  }
`;

export const ErrorMessage = styled.p`
  ${twoXs};
  color: ${({ theme }) => theme.colors.mediumStrawberry};
  margin: 0;
`;

export const PrependWrapper = styled.div`
  left: 10px;
  position: absolute;
  z-index: 1;
  top: 18px;
`;
