import { gql } from '@apollo/client';

export interface CustomizedState {
  totalPageCount: number;
}

export const CREATE_AGENCY = gql`
  mutation createAgency($name: String!, $email: String!) {
    createAgency(name: $name, email: $email)
  }
`;
