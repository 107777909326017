import Select, { ActionMeta, SingleValue } from 'react-select';

import { Wrapper, InputWrapper, ErrorMessage, Alert } from './styles';

interface Option {
  value: string;
  label: string;
}

interface Props {
  label: string;
  className?: string;
  errorText?: string;
  showError?: boolean;
  type?: string;
  required?: boolean;
  name: string;
  defaultValue?: Option;
  options: Option[];
  disabled?: boolean;
  loading?: boolean;
  clearable?: boolean;
  rtl?: boolean;
  searchable?: boolean;
  onChange?: (event: { value: string }) => void;
}

const SingleSelect: React.FC<Props> = ({
  label,
  className,
  showError,
  errorText = 'This is a required field',
  required = false,
  name,
  options,
  defaultValue = options[0],
  disabled = false,
  loading = false,
  clearable = false,
  rtl = false,
  searchable = false,
  onChange,
}) => (
  <Wrapper className={className} data-testid='single-select__wrapper'>
    {label}
    {required && '*'}
    <InputWrapper showError={showError} data-testid='single-select__input-wrapper'>
      <Select
        className='single-select-container'
        classNamePrefix='single-select'
        defaultValue={defaultValue}
        isDisabled={disabled}
        isLoading={loading}
        isClearable={clearable}
        isRtl={rtl}
        isSearchable={searchable}
        name={name}
        options={options}
        onChange={onChange as (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => void}
      />
      {showError && <Alert data-testid='single-select__alert' />}
    </InputWrapper>
    <ErrorMessage>{showError && errorText}</ErrorMessage>
  </Wrapper>
);

export default SingleSelect;
