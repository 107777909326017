import { ReactComponent as AlertSVG } from 'assets/svg/alert.svg';
import styled from 'styled-components';
import { twoXs, xs } from 'styles/typography';

export const Wrapper = styled.label`
  display: block;
  width: 100%;
  ${xs}
`;

export const InputWrapper = styled.div<{ showError?: boolean }>`
  position: relative;
  width: 100%;
  margin-top: 6px;

  .single-select__control {
    border-color: ${({ theme, showError }) => (showError ? theme.colors.mediumStrawberry : theme.colors.silver200)};
  }

  .single-select__control--is-focused {
    border-color: ${({ theme, showError }) => (showError ? theme.colors.mediumStrawberry : theme.colors.silver200)} !important;
    box-shadow: 0 0 0 1px ${({ theme, showError }) => (showError ? theme.colors.mediumStrawberry : theme.colors.silver200)};
  }

  .single-select__option--is-focused {
    background-color: ${({ theme }) => theme.colors.silver400} !important;
    color: #000000;
  }

  .single-select__option--is-selected {
    background-color: ${({ theme }) => theme.colors.silver200} !important;
    color: #000000;
  }
`;

export const Alert = styled(AlertSVG)`
  position: absolute;
  top: 11px;
  right: 50px;
`;

export const ErrorMessage = styled.p`
  ${twoXs};
  color: ${({ theme }) => theme.colors.mediumStrawberry};
`;
