import { createContext, Dispatch, useContext } from 'react';

import { NotificationActions, NotificationState } from './notification.reducer';

export const NotificationStateContext = createContext<NotificationState | undefined>(undefined);
export const NotificationDispatchContext = createContext<Dispatch<NotificationActions> | undefined>(undefined);

export const useNotificationState = (): NotificationState => {
  const context = useContext(NotificationStateContext);
  if (!context) {
    throw new Error('You did not call useNotificationState in a component that is provided for');
  }
  return context;
};

export const useNotificationDispatch = (): Dispatch<NotificationActions> => {
  const context = useContext(NotificationDispatchContext);
  if (!context) {
    throw new Error('You did not call useAuthState in a component that is provided for');
  }
  return context;
};
