import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ElipsesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #b7b7b7;
  :hover {
    cursor: pointer;
  }
`;

export const NameContainer = styled.div`
  line-height: 33px;
  margin-left: 15px;
`;

export const UtilitiesRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;
