import { useMutation } from '@apollo/client';
import { CUser } from 'cognito.types';
import TextInput from 'components/form-elements/text-input/text-input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Button, ErrorMessage } from 'routes/login/styles';
import { setTokenAction } from 'state/reducers/auth-reducer';
import { useAppDispatch } from 'state/state-hooks';
import { FormLabel, NewPasswordWrapper, SmallLogo } from '../new-password/styles';
import { COMPLETE_REGISTRATION } from 'utils/gql';
import { Auth } from 'aws-amplify';

interface Props {
  user: CUser;
}

const ConfirmName: React.FC<Props> = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  const [completeRegistration] = useMutation(COMPLETE_REGISTRATION);

  const handleSubmit = (values: { firstName: string; lastName: string }) => {
    const { firstName, lastName } = values;
    setLoading(true);
    Auth.updateUserAttributes(user, {
      family_name: lastName,
      given_name: firstName,
    }).then(() => {
      completeRegistration()
        .then(() => {
          dispatch(
            setTokenAction({ idToken: user.signInUserSession.idToken.jwtToken, refreshToken: user.signInUserSession.refreshToken.token })
          );
        })
        .catch(err => {
          console.error(err);
          setError(err.message);
          setLoading(false);
        });
    });
  };

  useEffect(() => {
    dispatch(setTokenAction({ idToken: user.signInUserSession.idToken.jwtToken, refreshToken: '' }));
  }, [user]);

  const formik = useFormik({
    initialValues: {
      firstName: user.attributes.given_name || '',
      lastName: user.attributes.family_name || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last Name is required'),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <NewPasswordWrapper data-testid='confirm-name__wrapper'>
      <SmallLogo />
      <form onSubmit={formik.handleSubmit} style={{ width: 340 }} data-testid='confirm-name__form'>
        <FormLabel>Please confirm your details.</FormLabel>
        <ErrorMessage>{error}</ErrorMessage>
        <TextInput
          label='First Name'
          testid='confirm-name__first-name-input'
          name='firstName'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          placeholder='First Name'
          errorText=''
          showError={false}
          width='100%'
          type='text'
        />
        <br />
        <TextInput
          label='Last Name'
          testid='confirm-name__last-name-input'
          name='lastName'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          placeholder='Last Name'
          errorText=''
          showError={false}
          width='100%'
          type='text'
        />
        <br />
        <Button type='submit' disabled={!formik.values.firstName || !formik.values.lastName} data-testid='confirm-name__submit-button'>
          {loading ? 'Loading...' : 'Submit'}
        </Button>
      </form>
    </NewPasswordWrapper>
  );
};

export default ConfirmName;
