import { gql, useQuery } from '@apollo/client';
import PageTitle from 'components/page-title/page-title';
import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/state-hooks';

import {
  AgenciesGrid,
  AgenciesWrapper,
  AgencyCard,
  CardIcon,
  TitleRow,
  PendingInvitesButton,
  PendingInvitesBadge,
  PendingInvitesButtonText,
} from './styles';
import { openModalAction } from 'state/reducers/invites-modal-reducer';

export const GET_MY_AGENCIES = gql`
  query {
    myAgencies {
      name
      id
    }
  }
`;

const AllAgencies: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data } = useQuery<{ myAgencies: Agency[] }>(GET_MY_AGENCIES);
  useBreadCrumbUpdate(['All Agencies']);
  const navigate = useNavigate();
  useEffect(() => {
    if (data?.myAgencies.length === 1) {
      navigate(`/agency/${data.myAgencies[0].id}`, { replace: true });
    }
  }, [data?.myAgencies]);

  const { user } = useAppSelector(state => state.auth);

  return (
    <>
      <AgenciesWrapper data-testid='all-agencies__wrapper'>
        <TitleRow>
          <PageTitle title='Agencies' />
          {user.attributes?.invites && user.attributes.invites.length > 0 && (
            <PendingInvitesButton
              onClick={() => {
                dispatch(openModalAction());
              }}
              data-testid='all-agencies__pending-invites-button'
            >
              <PendingInvitesBadge>
                <span>{user.attributes.invites.length}</span>
              </PendingInvitesBadge>
              <PendingInvitesButtonText>Pending Invites</PendingInvitesButtonText>
            </PendingInvitesButton>
          )}
        </TitleRow>
        <AgenciesGrid data-testid='all-agencies__grid'>
          {data &&
            data.myAgencies.length > 1 &&
            data?.myAgencies.map(agency => (
              <Link key={agency.id} to={`/agency/${agency.id}`} data-testid='all-agencies__agency-card'>
                <AgencyCard>
                  <CardIcon />
                  {agency.name}
                </AgencyCard>
              </Link>
            ))}
        </AgenciesGrid>
      </AgenciesWrapper>
    </>
  );
};

export default AllAgencies;
