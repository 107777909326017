import { useMutation } from '@apollo/client';
import loginImage from 'assets/images/login.jpg';
import TextInput from 'components/form-elements/text-input/text-input';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { SEND_FORGOT_PASSWORD } from './settings';
import { Button, Form, FormWrapper, Image, Logo, Wrapper, ErrorMessage, LoginNavLink, ResetPasswordContainer } from './styles';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const [sendForgotPassword, { loading }] = useMutation<{ requestForgotPassword: { isSucceed: boolean } }>(SEND_FORGOT_PASSWORD, {
    variables: { email },
  });

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    const res = await sendForgotPassword();

    if (res?.data?.requestForgotPassword?.isSucceed) {
      navigate(`/confirm_forgot_password/${encodeURIComponent(email)}`);
    } else {
      setError('Something went wrong. Please try again!');
    }
  };

  return (
    <Wrapper data-testid='forgot-password__wrapper'>
      <FormWrapper data-testid='forgot_form-wrapper'>
        <Form onSubmit={onSubmit}>
          <Logo display='block' />
          <ErrorMessage>{error}</ErrorMessage>
          <TextInput
            testid='forgot-password__email-input'
            label='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='Email'
            errorText='Invalid Email'
            required
            showError={false}
            width='100%'
            type='email'
          />
          <br />
          <ResetPasswordContainer>
            <LoginNavLink to='/'>
              <p>Login</p>
            </LoginNavLink>
            <Button type='submit' data-testid='forgot-password__submit-button'>
              Reset Password
            </Button>
          </ResetPasswordContainer>
        </Form>
      </FormWrapper>
      <Image src={loginImage} alt='' />
    </Wrapper>
  );
};

export default ForgotPassword;
