import SectionHeader from 'components/form-elements/section-header/section-header';
import SingleSelect from 'components/form-elements/single-select/single-select';
import PageTitle from 'components/page-title/page-title';
import LeftPane from 'components/split-pane/left-pane/left-pane';
import RightPane from 'components/split-pane/right-pane/right-pane';
import SplitPane from 'components/split-pane/split-pane';
import styled from 'styled-components';

const options = [
  { value: 'ia', label: 'Iowa' },
  { value: 'mn', label: 'Minnesota' },
  { value: 'ut', label: 'Utah' },
];

const AddCompany: React.FC = () => (
  <SplitPane data-testid='add-company__split-pane'>
    <LeftPane>
      <PageTitle title='+ Add Company' />
    </LeftPane>
    <RightPane>
      <>
        <SectionHeader title={`Company's Info`} />
        <SectionHeader title='Text Message' />
        <FormRow>
          <HalfColumn>
            <SingleSelect className='basic-single' label='State' defaultValue={options[0]} name='state' options={options} />
          </HalfColumn>
        </FormRow>
      </>
    </RightPane>
  </SplitPane>
);

const FormRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const HalfColumn = styled.div`
  display: flex;
  width: 50%;
`;

export default AddCompany;
