import { ReactComponent as SmallLogoSVG } from 'assets/svg/sidebar-logo.svg';
import styled from 'styled-components';
import { ButtonXS } from 'styles/buttons';
import { boldMedium, mediumMedium, mediumSmall, small } from 'styles/typography';

export const ConfirmForgotPasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
`;

export const SmallLogo = styled(SmallLogoSVG)`
  width: 74px;
  margin-bottom: 62px;
`;

export const Button = styled.button`
  ${ButtonXS}
  ${mediumSmall}

  background: ${({ theme }) => theme.colors.mediumPink};
  color: ${({ theme }) => theme.colors.white};
  width: 153px;
  margin-top: 26px;
  :disabled {
    background: ${({ theme }) => theme.colors.silver400};
    color: ${({ theme }) => theme.colors.shuttleGray};
  }
`;

export const FormLabel = styled.p`
  ${small}
  color: ${({ theme }) => theme.colors.silver500};
  width: 100%;
  margin-bottom: 34px;
`;

export const ResetPasswordHeader = styled.span`
  ${boldMedium}
  color: ${({ theme }) => theme.colors.codGray};
`;

export const ResetPasswordDescription = styled.span`
  display: block;
  ${mediumMedium}
  color: ${({ theme }) => theme.colors.codGray};
  margin-top: 10px;
`;
