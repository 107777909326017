import { ReactComponent as CardIcon } from 'assets/svg/card-icon.svg';
import { ReactComponent as KioskIconSVG } from 'assets/svg/kiosk-icon.svg';
import styled from 'styled-components';

import theme from '../../styles/theme';
import { twoXs } from '../../styles/typography';

export const LinkWrapper = styled.div<{ active?: boolean }>`
  ${twoXs};
  height: 62px;
  text-transform: uppercase;
  background-color: ${({ active }) => active && theme.colors.silver200};
  color: ${({ active }) => (active ? theme.colors.mediumPink : theme.colors.silver400)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const AgencyIcon = styled(CardIcon)<{ active?: boolean }>`
  width: 28px;
  height: 28px;
  color: ${({ active }) => active && theme.colors.darkPink};
`;

export const KioskIcon = styled(KioskIconSVG)<{ active?: boolean }>`
  width: 23px;
  margin: 9px 0 6px;
`;
