import styled from 'styled-components';
import { FormControlsWrapper } from '../../styles/base';

export const FormWrapper = styled.div`
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.div`
  margin-top: 27px;
`;

export const FormControls = styled(FormControlsWrapper)`
  margin-bottom: 30px;
  justify-content: flex-start;
`;
