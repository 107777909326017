import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { ReactComponent as XIconSVG } from '../../assets/svg/x-icon.svg';

interface Props {
  title: string;
  open: boolean;
  height?: string;
  onClose: () => void;
  icon?: ReactElement;
}

const Modal: React.FC<Props> = ({ title, open, height, children, onClose, icon }) => {
  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  return (
    <>
      {open && (
        <Wrapper onClick={onClose} data-testid='modal__wrapper' onMouseLeave={stopPropagation}>
          <Container onClick={stopPropagation} data-testid='modal__content-container' height={height}>
            <Header>
              {icon}
              {title}
            </Header>
            <XIcon onClick={onClose} data-testid='modal__x-button' />
            <Content>{children}</Content>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  z-index: 100;
  background-color: rgba(90, 90, 90, 0.5);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
`;

const Container = styled.div<{ height?: string }>`
  width: 610px;
  ${({ height }) => (height ? `min-height: ${height}` : 'height: 467px')};
  background: #ffffff;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
`;

const Header = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 41px;
  background: #e9e9e9;
  margin: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const XIcon = styled(XIconSVG)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  cursor: pointer;
`;

export default Modal;
