import { ReactComponent as CardIconSVG } from 'assets/svg/card-icon.svg';
import styled from 'styled-components';
import { boldLarge, mediumTwoXs, mediumXs } from 'styles/typography';

export const AgenciesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AgenciesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 301px 301px 301px;
  grid-gap: 20px 24px;

  @media (min-width: 961px) and (max-width: 1100px) {
    grid-template-columns: 301px 301px;
  }
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AgencyCard = styled.button`
  ${boldLarge};
  width: 100%;
  height: 130px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: none;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
`;

export const CardIcon = styled(CardIconSVG)`
  margin-right: 8px;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const PendingInvitesButton = styled.button`
  ${mediumXs};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: none;
  margin-left: 20px;
  align-items: center;
  background: transparent;
  cursor: pointer;
`;

export const PendingInvitesBadge = styled.div`
  ${mediumTwoXs};
  display: flex;
  height: 20px;
  width: 20px;
  background-color: ${({ theme }) => theme.colors.mediumPink};
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
  margin-right: 5px;
  font-size: 9px;
  color: ${({ theme }) => theme.colors.white};
`;

export const PendingInvitesButtonText = styled.span`
  color: ${({ theme }) => theme.colors.mediumPink};
`;
