import { useState } from 'react';
import Pagination from '../pagination';

export const paginationDefaults = {
  pageSize: 15,
  currentPage: 1,
};

export const usePagination = () => {
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const updatePagination = (page: number) => {
    setCurrentPage(page);
  };

  const renderPagination = (count: number) => {
    const totalPageCount = Math.ceil(count / pageSize);
    if (totalPageCount > 1)
      return <Pagination updatePagination={updatePagination} currentPage={currentPage} totalPageCount={totalPageCount} />;
  };

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    renderPagination,
  };
};
