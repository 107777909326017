import { ApolloError, ApolloQueryResult, gql, OperationVariables, useMutation } from '@apollo/client';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import SingleSelect from 'components/form-elements/single-select/single-select';
import TextInput from 'components/form-elements/text-input/text-input';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AgencyInvite } from './interface';

const userAgencyRoles = [
  { value: 'MEMBER', label: 'member' },
  { value: 'ADMIN', label: 'admin' },
];

export const SEND_INVITE = gql`
  mutation sendInvite($email: String!, $agencyId: Int!, $role: UserAgencyRole!) {
    createInvite(email: $email, agencyId: $agencyId, role: $role) {
      email
    }
  }
`;

interface Props {
  agencyId: number;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      agencyInvites: AgencyInvite[];
    }>
  >;
}
const NewInvite: React.FC<Props> = ({ agencyId, refetch }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [role, setRole] = useState(userAgencyRoles[0].value);

  const handleRoleSelect = ({ value }: { value: string }) => {
    setRole(value);
  };
  const [sendInvite] = useMutation<{ createInvite: { email: string } }>(SEND_INVITE);
  const [inviteSent, setInviteSent] = useState(false);

  const handleSentInvite = () => {
    setInviteSent(true);
    setEmail('');
    setError('');
    void refetch();
    setTimeout(() => {
      setInviteSent(false);
    }, 2000);
  };
  return (
    <Wrapper data-testid='new-invite__wrapper'>
      <Title>Invite</Title>
      <Input
        value={email}
        label={''}
        onChange={e => setEmail(e.target.value)}
        errorText={error}
        showError={!!error}
        placeholder='Email Address'
        width='76.3%'
        testid='new-invite__input'
      />
      <Select label='' name='' options={userAgencyRoles} onChange={handleRoleSelect} />
      <Button
        data-testid='new-invite__button'
        disabled={!email}
        onClick={() => {
          void sendInvite({ variables: { email, agencyId: Number(agencyId), role } })
            .then(() => {
              handleSentInvite();
            })
            .catch((e: ApolloError) => {
              setError(e.message);
            });
        }}
      >
        {inviteSent ? (
          <>
            <Check />
            Invite Sent
          </>
        ) : (
          'Send Invite'
        )}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 9px;
  width: 100%;
`;

const Input = styled(TextInput)``;

const Button = styled.button`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  height: 37px;
  border-radius: 6px;
  border: none;
  background: ${({ theme }) => theme.colors.mediumPink};
  color: white;
  margin: 6px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  :disabled {
    background: ${({ theme }) => theme.colors.silver400};
  }
`;

const Select = styled(SingleSelect)`
  span {
    width: 0;
  }
  width: 86px;
  text-align: right;
  div {
    border: 0;
    box-shadow: 0 0 0 0 !important;
  }

  .single-select__indicator {
    padding: 0;
  }
  .single-select__value-container {
    padding: 0;
  }
  .single-select__single-value {
    font-size: 14px;
  }
  position: absolute;
  top: 112px;
  right: 165px;
  border-top: 1px;
  border-bottom: 1px;

  .single-select__control {
    min-height: 34px;
  }
`;

const Check = styled(CheckSVG)`
  margin-right: 10px;
`;
export default NewInvite;
