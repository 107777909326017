import $ from 'jquery';
import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { useNavigate } from 'react-router';
import { useNotificationDispatch } from '../../context/notification/notification.context';
import { showToast } from '../../context/notification/notification.reducer';

declare const window: any;

const CreateCompany: React.FC = () => {
  window.jQuery = $; // jQuery is required for using HubSpot forms @link https://github.com/escaladesports/react-hubspot-form/issues/22

  const navigate = useNavigate();
  const dispatch = useNotificationDispatch();

  const onSubmitted = () => {
    dispatch(showToast('Company submitted successfully'));
    navigate(-1);
  };

  return (
    <HubspotForm
      portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
      formId={process.env.REACT_APP_HUBSPOT_FORM_ID}
      onFormSubmitted={onSubmitted}
      loading={<div>Loading...</div>}
      // eslint-disable-next-line
      onSubmit={() => {}} // onSubmit is required
    />
  );
};

export default CreateCompany;
