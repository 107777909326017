import { useQuery } from '@apollo/client';
import { SortDirections } from 'enums';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { useTableData } from './useTableData';

interface Pagination {
  page: number;
  pageSize: number;
}

interface Order {
  sort: SortDirections;
  field: string;
}

interface QueryVariables {
  order: Order;
  pagination: Pagination;
  search: string;
  agencyId?: number;
}
export const useTableQuery = (query: DocumentNode, variables: QueryVariables) => {
  const [isLoading, setIsLoading] = useState(true);

  // This seems to be an incorrect type cast from Apollo so I had to add a ts-ignore here
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const objectKey = query.definitions[0].selectionSet.selections[0].name.value as string;

  const { data, previousData, error, loading, refetch } = useQuery<{
    [key: string]: {
      data: any[];
      count: number;
      totalLocations?: number;
      totalProviders?: number;
    };
  }>(query, {
    onCompleted: () => setIsLoading(false),
    variables,
  });

  const { tableData, count, totalLocations, totalProviders } = useTableData(objectKey, loading, data, previousData);

  return {
    tableData,
    error,
    count,
    isLoading,
    refetch,
    totalLocations,
    totalProviders,
  };
};
