import { gql } from '@apollo/client';

export const GET_COGNITO_PASSWORD_REQUIREMENTS = gql`
  query PasswordRequirements {
    cognitoPasswordRequirements {
      MinimumLength
      RequireUppercase
      RequireLowercase
      RequireNumbers
      RequireSymbols
    }
  }
`;
