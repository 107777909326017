/* eslint-disable no-shadow */
export enum UserAgencyRole {
  owner = 'owner',
  admin = 'admin',
  member = 'member',
}

export enum SortDirections {
  ASC = 'asc',
  DESC = 'desc',
}
export enum CompanySortedFields {
  name = 'name',
  ownerName = 'ownerName',
  createdAt = 'createdAt',
  locationsCount = 'locationsCount',
  providersCount = 'providersCount',
}

export enum UserSortedFields {
  name = 'name',
  email = 'email',
  createdAt = 'createdAt',
  role = 'role',
}

export enum KioskSortedFields {
  name = 'name',
  ownerName = 'ownerName',
  createdAt = 'createdAt',
  numCompanies = 'numCompanies',
  numUsers = 'numUsers',
}
/* eslint-enable no-shadow */
