import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  mutation {
    getSignedInUserDetails {
      userId
      email
      given_name
      family_name
      isKioskUser
      picture
      phone_number
      invites {
        id
        token
        role
        invitedAt
        agency {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ACCEPT_INVITE = gql`
  mutation acceptInvite($token: String!, $input: UserInput!) {
    acceptInvite(token: $token, input: $input) {
      agencyId
    }
  }
`;

export const REMOVE_AGENCY_INVITE = gql`
  mutation removeAgencyInvite($id: Int!) {
    removeAgencyInvite(id: $id)
  }
`;

export const COMPLETE_REGISTRATION = gql`
  mutation completeRegistration($acceptedTerms: Boolean) {
    completeRegistration(acceptedTerms: $acceptedTerms)
  }
`;
