import styled from 'styled-components';

export const ButtonXS = `
  border-radius: 6px;
  padding: 6px 12px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
`;

export const BaseButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 6px;
  padding: 6px 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
`;

export const ButtonXsWhite = styled(BaseButton)`
  border: 1px solid #e21979;
  font-size: 12px;
  line-height: 18px;
  width: 102px;
  height: 30px;
  color: #e21979;
  background-color: #f5f5f5;
`;

export const ButtonXsPink = styled(BaseButton)`
  border: 1px solid #e21979;
  font-size: 12px;
  line-height: 18px;
  width: auto;
  height: 30px;
  color: #f5f5f5;
  background-color: #e21979;
`;
