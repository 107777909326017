import React from 'react';
import { useQuery } from '@apollo/client';
import { AgencyInvite } from './interface';
import NewInvite from './new-invite';
import PendingInvites from './pending-invites';
import { GET_AGENCY_INVITES } from './settings';
import { PendingInviteContainer, Wrapper } from './styles';

interface Props {
  agency: Agency;
}

const InviteUsers: React.FC<Props> = ({ agency }) => {
  const { data, loading, refetch } = useQuery<{ agencyInvites: AgencyInvite[] }>(GET_AGENCY_INVITES, {
    variables: {
      agencyId: Number(agency.id),
    },
  });
  return (
    <Wrapper data-testid='invite-users__wrapper'>
      <NewInvite agencyId={agency.id} refetch={refetch} />
      <PendingInviteContainer>
        <PendingInvites data={data} refetch={refetch} loading={loading} />
      </PendingInviteContainer>
    </Wrapper>
  );
};

export default InviteUsers;
