import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { REMOVE_AGENCY_INVITE } from 'utils/gql';

import CopyInviteLink from './copy-invite-link';
import { AgencyInvite } from './interface';
import ResendInvite from './resend-invite';
import {
  InviteList,
  InviteListContainer,
  NoDataMessage,
  ItemCopyLinkCell,
  ItemEmailCell,
  ItemResendInviteCell,
  ItemRoleCell,
  ListItem,
  TitleContainer,
  StyledCancelIcon,
  RemoveButton,
  DeleteInviteContainer,
  ButtonContainer,
  DeleteButton,
} from './styles';

interface Props {
  data: { agencyInvites: AgencyInvite[] } | undefined;
  loading: boolean;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      agencyInvites: AgencyInvite[];
    }>
  >;
}

const PendingInvites: React.FC<Props> = ({ data, loading, refetch }) => {
  const [removeAgencyInvite] = useMutation(REMOVE_AGENCY_INVITE);

  const [isDeletingInvite, setIsDeletingInvite] = useState(false);
  const [agencyInviteToDelete, setAgencyInviteToDelete] = useState<AgencyInvite>();

  const handleDeleteInvite = (agencyInvite: AgencyInvite) => {
    setAgencyInviteToDelete(agencyInvite);
    setIsDeletingInvite(true);
  };

  const confirmDeleteInvite = (agencyInvite: AgencyInvite) => {
    void removeAgencyInvite({ variables: { id: agencyInvite.id } }).then(() => {
      setAgencyInviteToDelete(undefined);
      setIsDeletingInvite(false);
      void refetch();
    });
  };

  const cancelDeleteInvite = () => {
    setAgencyInviteToDelete(undefined);
    setIsDeletingInvite(false);
  };

  return loading ? (
    <p>Loading...</p>
  ) : (
    <>
      <TitleContainer data-testid='pending-invites__title'>Pending Invites</TitleContainer>
      {isDeletingInvite && agencyInviteToDelete && (
        <DeleteInviteContainer data-testid='pending-invites__remove-confirm'>
          Are you sure you want to delete the invitation sent to {agencyInviteToDelete.email}?
          <ButtonContainer>
            <DeleteButton onClick={() => confirmDeleteInvite(agencyInviteToDelete)} data-testid='pending-invites__remove-confirm-yes'>
              Yes
            </DeleteButton>
            <DeleteButton onClick={() => cancelDeleteInvite()} data-testid='pending-invites__remove-confirm-no'>
              No
            </DeleteButton>
          </ButtonContainer>
        </DeleteInviteContainer>
      )}
      <InviteListContainer count={data ? data?.agencyInvites?.length : 0}>
        <InviteList>
          {data?.agencyInvites && data?.agencyInvites?.length > 0 ? (
            <>
              {data?.agencyInvites.map((item, index) => (
                <ListItem key={`row-${index}`} data-testid='pending-invites__li'>
                  <RemoveButton data-testid={`pending-invites__li-remove-button-${index}`} onClick={() => handleDeleteInvite(item)}>
                    <StyledCancelIcon />
                  </RemoveButton>
                  <ItemEmailCell data-testid='pending-invites__li-email'>{item?.email}</ItemEmailCell>
                  <ItemRoleCell data-testid='pending-invites__li-role'>{item?.role.toLowerCase()}</ItemRoleCell>
                  <ItemResendInviteCell>
                    <ResendInvite agencyInvite={item} index={index} refetch={refetch} />
                  </ItemResendInviteCell>
                  <ItemCopyLinkCell>
                    <CopyInviteLink token={item.token} />
                  </ItemCopyLinkCell>
                </ListItem>
              ))}
            </>
          ) : (
            <NoDataMessage data-testid='pending-invites__no-data'>There are currently no pending invites</NoDataMessage>
          )}
        </InviteList>
      </InviteListContainer>
    </>
  );
};

export default PendingInvites;
