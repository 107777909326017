import { toast, TypeOptions } from 'react-toastify';

import { toastSettings } from '../../utils/base-settings';

/* eslint-disable-next-line no-shadow */
export enum NotificationActionTypes {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION',
}

export interface NotificationState {
  notificationType: TypeOptions;
  msg: string;
}

export interface CallNotificationAction extends NotificationState {
  type: NotificationActionTypes.SET_NOTIFICATION;
}

export interface ClearNotificationAction extends NotificationState {
  type: NotificationActionTypes.CLEAR_NOTIFICATION;
}

export type NotificationActions = CallNotificationAction | ClearNotificationAction;

export const initialNotificationState: NotificationState = {
  notificationType: 'default',
  msg: '',
};

export const showToast = (msg: string, notificationType: TypeOptions = 'default'): CallNotificationAction => {
  if (notificationType !== 'default') {
    toast[notificationType](msg, toastSettings);
  } else {
    toast(msg, toastSettings);
  }

  return {
    type: NotificationActionTypes.SET_NOTIFICATION,
    notificationType,
    msg,
  };
};

export const clearNotification = (): ClearNotificationAction => ({
  type: NotificationActionTypes.CLEAR_NOTIFICATION,
  ...initialNotificationState,
});

export function notificationReducer(prevState = initialNotificationState, action: NotificationActions): NotificationState {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATION:
      return {
        notificationType: action.notificationType,
        msg: action.msg,
      };
    case NotificationActionTypes.CLEAR_NOTIFICATION:
      return {
        ...initialNotificationState,
      };
    default:
      return {
        ...prevState,
      };
  }
}
