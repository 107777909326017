import { createAction, createReducer } from '@reduxjs/toolkit';

export const openModalAction = createAction('OPEN_MODAL');
export const closeModalAction = createAction('CLOSE_MODAL');
export const setInvitesListChangedAction = createAction<boolean>('SET_IVITES_LIST_CHANGED');

interface ModalState {
  isOpen: boolean;
  invitesListChanged: boolean;
}

const initialState: ModalState = {
  isOpen: false,
  invitesListChanged: false,
};

export const invitesModalReducer = createReducer(initialState, builder => {
  builder
    .addCase(openModalAction, state => {
      state.isOpen = true;
    })
    .addCase(closeModalAction, state => {
      state.isOpen = false;
    })
    .addCase(setInvitesListChangedAction, (state, action) => {
      state.invitesListChanged = action.payload;
    });
});
