import { gql, useQuery } from '@apollo/client';
import { unAuthorizedErrorMessage } from 'app-constants';
import PageTitle from 'components/page-title/page-title';
import { UserAgencyRole } from 'enums';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Companies from './companies';
import { Wrapper } from './styles';
import Users from './users';

export const GET_AGENCY = gql`
  query getAgency($agencyId: Int!) {
    agency(id: $agencyId) {
      agency {
        name
        id
      }
      role
    }
  }
`;
const Agency: React.FC = () => {
  const navigate = useNavigate();
  const { agencyId } = useParams();
  const { data, loading, error } = useQuery<{ agency: { agency: Agency; role: UserAgencyRole } }>(GET_AGENCY, {
    variables: {
      agencyId: Number(agencyId as string),
    },
  });

  useEffect(() => {
    if (error?.message) {
      if (error.message === unAuthorizedErrorMessage) navigate('/');
    }
  }, [error]);
  return (
    <Wrapper data-testid='agency__wrapper'>
      {loading ? (
        <p data-testid='agency__loading'>loading...</p>
      ) : (
        <>
          <PageTitle title={data?.agency.agency.name || ''} />
          <Links data-testid='agency__links'>
            <LinkWrapper selected={location.pathname.includes('companies')}>
              <Link to='companies'>Companies</Link>
            </LinkWrapper>
            <LinkWrapper selected={location.pathname.includes('users')}>
              <Link to='users'>Users</Link>
            </LinkWrapper>
          </Links>
          {data && (
            <Routes>
              <Route path='companies' element={<Companies agency={data.agency.agency} role={data.agency.role} />} />
              <Route path='users' element={<Users agency={data.agency.agency} role={data.agency.role} />} />
              <Route path='*' element={<Navigate to='companies' />} />
            </Routes>
          )}
        </>
      )}
    </Wrapper>
  );
};
const Links = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;
const LinkWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  margin-bottom: 8px;
  ::after {
    position: absolute;
    bottom: -8px;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.black};
  }
  a {
    color: ${({ theme, selected }) => (selected ? theme.colors.black : theme.colors.silver400)};
  }
  margin-right: 32px;
`;

export default Agency;
