import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';
import React, { useState } from 'react';
import { Count, Header } from 'components/data-table/styles';

import { ReactComponent as CompanyIcon } from '../../../assets/svg/company-icon.svg';
import { ReactComponent as ElipsesIcon } from '../../../assets/svg/elipses-icon.svg';
import { Link } from 'react-router-dom';
import { ButtonXsPink } from '../../../components/buttons';
// import { ReactComponent as MessageIcon } from '../../../assets/svg/message-icon.svg';
// import { ReactComponent as ReviewsIcon } from '../../../assets/svg/reviews-icon.svg';
// import BadgedIcon from '../../../components/badged-icon/badged-icon';
import Search from '../../../components/search/search';

import { medium } from '../../../styles/typography';

import { AgencyCompany, Props } from './interfaces';
import Launch from './launch';
import { GET_AGENCY_COMPANIES } from './settings';
import styled from 'styled-components';
import { useAppSelector } from 'state/state-hooks';
import DataTable from 'components/data-table';
import { useTableQuery } from 'components/data-table/hooks/useTableQuery';
import { useTableSort } from 'components/data-table/hooks/useTableSort';
import { usePagination } from 'components/data-table/hooks/usePagination';

const Companies: React.FC<Props> = ({ agency }) => {
  useBreadCrumbUpdate([agency.name, 'Companies']);
  const { user } = useAppSelector(state => state.auth);

  const [search, setSearch] = useState('');

  const { currentSortedColumn, sortDirection, handleSort } = useTableSort();
  const { currentPage, setCurrentPage, pageSize, renderPagination } = usePagination();

  const { tableData, count, isLoading, totalLocations, totalProviders } = useTableQuery(GET_AGENCY_COMPANIES, {
    agencyId: +agency.id,
    pagination: {
      page: currentPage,
      pageSize,
    },
    order: {
      sort: sortDirection,
      field: currentSortedColumn,
    },
    search,
  });

  const handleSearch = (newSearch: string) => {
    setCurrentPage(1);
    setSearch(newSearch);
  };

  const columns = [
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('name')}
          isSortable
          isSorting={currentSortedColumn === 'name'}
          direction={sortDirection}
        >
          Company
        </Header>
      ),
      component: /* istanbul ignore next */ (company: AgencyCompany) => (
        <>
          <CompanyIcon />
          <CompanyName>{company.name}</CompanyName>
        </>
      ),
      columnStyles: `
          width: 40.9%;
          padding-right: 8px;
        `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('ownerName')}
          isSortable
          isSorting={currentSortedColumn === 'ownerName'}
          direction={sortDirection}
        >
          Owner Name
        </Header>
      ),
      component: /* istanbul ignore next */ (company: AgencyCompany) => <>{company.ownerName}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('createdAt')}
          isSortable
          isSorting={currentSortedColumn === 'createdAt'}
          direction={sortDirection}
        >
          Date Created
        </Header>
      ),
      component: /* istanbul ignore next */ (company: AgencyCompany) => <>{new Date(company.createdAt).toLocaleDateString('en')}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('locationsCount')}
          isSortable
          isSorting={currentSortedColumn === 'locationsCount'}
          direction={sortDirection}
        >
          # of Locations
        </Header>
      ),
      component: /* istanbul ignore next */ (company: AgencyCompany) => <>{company.locationsCount}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: (
        <Header
          onClick={/* istanbul ignore next */ () => handleSort('providersCount')}
          isSortable
          isSorting={currentSortedColumn === 'providersCount'}
          direction={sortDirection}
        >
          # of Providers
        </Header>
      ),
      component: /* istanbul ignore next */ (company: AgencyCompany) => <>{company.providersCount}</>,
      columnStyles: `
          width: 199px;
      `,
    },
    {
      header: <Header>Actions</Header>,
      component: /* istanbul ignore next */ (company: AgencyCompany) => (
        <ActionsWrapper>
          {!user.attributes.isKioskUser && <Launch companyId={company.id} data-testId='companies__launch-button' />}
          <Elipses />
        </ActionsWrapper>
      ),
      columnStyles: `
          width: 172px;
      `,
    },
  ];

  return (
    <>
      <ControlsWrapper>
        <Search search={handleSearch} dataTestId='table__data-search' />
        <Link to={`/partner-form?swell_partner___backend=${agency.name}`}>
          <CreateCompanyBtn>+ New Company</CreateCompanyBtn>
        </Link>
      </ControlsWrapper>
      <CountsWrapper>
        <Count data-testid='companies__count'>{count} Companies</Count>
        <Spacer />
        <Count data-testid='compamies__locations-count'>{totalLocations} Locations</Count>
        <Spacer />
        <Count data-testid='compamies__providers-count'>{totalProviders} Providers</Count>
      </CountsWrapper>

      <DataTable data={tableData} loading={isLoading} columns={columns} />
      {!isLoading && renderPagination(count)}
    </>
  );
};

const CreateCompanyBtn = styled(ButtonXsPink)`
  min-width: 128px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CountsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  height: 18px;
  width: 1px;
  background: ${/* istanbul ignore next */ ({ theme }) => theme.colors.silver500};
  margin: 0 8px;
`;

const CompanyName = styled.p`
  ${medium}
  color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.silver900};
  margin-left: 8px;
`;

const Elipses = styled(ElipsesIcon)`
  color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.silver500};
  marin: 0 10px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 12px;
`;
export default Companies;
