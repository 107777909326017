import { gql } from '@apollo/client';

export const CONFIRM_FORGOT_PASSWORD = gql`
  mutation resetForgotPassword($email: String!, $code: String!, $password: String!) {
    resetForgotPassword(email: $email, code: $code, password: $password) {
      isSucceed
      errCode
    }
  }
`;
