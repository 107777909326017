import { CognitoPasswordRequirements } from './password-validation.types';

export function validatePassword(password: string, requirements: CognitoPasswordRequirements) {
  const errors = [];

  if (password.match(/\s/)) {
    errors.push("Password shouldn't contain whitespaces");
  }

  if (requirements.MinimumLength && password.length < requirements.MinimumLength) {
    errors.push(`Password should contain ${requirements.MinimumLength} or more symbols`);
  }

  if (requirements.RequireLowercase && !password.match(/(?=.*[a-z])/)) {
    errors.push('Password should contain at least one character in lowercase');
  }

  if (requirements.RequireUppercase && !password.match(/(?=.*[A-Z])/)) {
    errors.push('Password should contain at least one character in uppercase');
  }

  if (requirements.RequireNumbers && !password.match(/(?=.*[0-9])/)) {
    errors.push('Password should contain numbers');
  }
  //eslint-disable-next-line
  if (requirements.RequireSymbols && !password.match(/(?=.*[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\’:;|_~`])/)) {
    errors.push('Password should contain special characters');
  }

  return errors;
}

export function prettifyErrorMessage(requirements: CognitoPasswordRequirements) {
  const messageParts = [];

  const requirementsMap: { [key: string]: any } = {
    RequireLowercase: '1 lowercase',
    RequireUppercase: '1 uppercase',
    RequireNumbers: '1 number',
    RequireSymbols: '1 symbol',
  };

  for (const property in requirements) {
    const value = requirements[property as keyof CognitoPasswordRequirements];

    if (value && requirementsMap[property]) {
      messageParts.push(requirementsMap[property]);
    }
  }

  return `Password requires ${messageParts.join(', ')}`;
}
