import { useQuery } from '@apollo/client';
import { setPasswordValidationRules } from '../../state/reducers/password-validation-rules-reducer';
import { useAppSelector, useAppDispatch } from '../../state/state-hooks';
import { CognitoPasswordRequirements } from './password-validation.types';
import { GET_COGNITO_PASSWORD_REQUIREMENTS } from './settings';

function usePasswordValidation() {
  const passwordValidation = useAppSelector(state => state.passwordValidation);

  if (passwordValidation.isLoaded) {
    return passwordValidation.rules;
  }

  const { data, error } = useQuery<{ cognitoPasswordRequirements: CognitoPasswordRequirements }>(GET_COGNITO_PASSWORD_REQUIREMENTS, {});

  if (error) {
    return {}; // In order to not break form validation in case when backend didn't send validation requirements
  }

  if (data?.cognitoPasswordRequirements) {
    const dispatch = useAppDispatch();
    const { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase } = data.cognitoPasswordRequirements;

    const rules = { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase };

    dispatch(setPasswordValidationRules(rules));

    return rules;
  }
}

export default usePasswordValidation;
