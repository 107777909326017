import styled from 'styled-components';

import theme from './theme';
import { xs } from './typography';

export const ButtonXS = `
  border-radius: 6px;
  padding: 6px 12px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
`;

export const ButtonXsWhite = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 6px;
  padding: 6px 12px;
  border: 1px solid ${theme.colors.mediumPink};
  ${xs};
  line-height: 18px;
  width: 102px;
  height: 30px;
  color: ${theme.colors.mediumPink};
  background-color: ${theme.colors.silver100};
  cursor: pointer;
`;
