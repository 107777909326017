import { CardIcon } from 'components/icons/card-icon';

// eslint-disable-next-line no-shadow
export enum HeaderActionTypes {
  UPDATE_BREADCRUMBS,
}

export type BreadCrumbIcon = React.FunctionComponent<React.SVGAttributes<SVGElement>>;

export interface UpdateBreadCrumbsAction {
  type: HeaderActionTypes.UPDATE_BREADCRUMBS;
  breadcrumbs: string[];
  Icon: BreadCrumbIcon;
}

export const updateBreadCrumbs = (breadcrumbs: string[], Icon?: BreadCrumbIcon): UpdateBreadCrumbsAction => ({
  type: HeaderActionTypes.UPDATE_BREADCRUMBS,
  breadcrumbs,
  Icon: Icon ? () => <Icon /> : () => <CardIcon />,
});

export type HeaderActions = UpdateBreadCrumbsAction;

export interface HeaderState {
  breadcrumbs: string[];
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

export const initialHeaderState: HeaderState = {
  breadcrumbs: [],
  Icon: () => <CardIcon />,
};

export function headerReducer(prevState = initialHeaderState, action: HeaderActions): HeaderState {
  switch (action.type) {
    case HeaderActionTypes.UPDATE_BREADCRUMBS:
      return {
        ...prevState,
        breadcrumbs: action.breadcrumbs,
        Icon: action.Icon,
      };
    default:
      return {
        ...prevState,
      };
  }
}
