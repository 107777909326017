import React, { useState } from 'react';
import Radio from '../../components/form-elements/radio/radio';
import { FormWrapper, Form, FormControls } from './styles';
import { CancelBtn, SubmitBtn } from '../../styles/base';
import { useNavigate } from 'react-router';

interface Props {
  email: {
    currentOwnerEmail: string;
    newOwnerEmail: string;
  };
  action: (role: string) => void;
}

const RoleForm: React.FC<Props> = ({ email, action }) => {
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  return (
    <FormWrapper data-testid='role-form'>
      <p>
        You are changing the owner of this agency to <b>{email.newOwnerEmail}</b>
      </p>
      <p>
        Please reassign the current owner, <b>{email.currentOwnerEmail}</b>, to a different role:
      </p>

      <Form>
        <Radio label={'Administrator'} value={'admin'} checked={role === 'admin'} onChange={e => setRole(e.target.value)} id={'admin'} />

        <Radio label={'Member'} value={'member'} checked={role === 'member'} onChange={e => setRole(e.target.value)} id={'member'} />

        <Radio
          label={'Remove from Agency'}
          value={'remove'}
          checked={role === 'remove'}
          onChange={e => setRole(e.target.value)}
          id={'remove'}
        />
      </Form>

      <FormControls>
        <CancelBtn onClick={() => navigate('/', { replace: true })} data-testid='cancel-form'>
          Cancel
        </CancelBtn>
        <SubmitBtn disabled={!role} onClick={() => role && action(role)} data-testid='submit-form'>
          Update and Reassign
        </SubmitBtn>
      </FormControls>
    </FormWrapper>
  );
};

export default RoleForm;
