import { ApolloError, useMutation } from '@apollo/client';
import { ReactComponent as TigersSVG } from 'assets/svg/image-error-tigers.svg';
import { ReactComponent as LogoSVG } from 'assets/svg/sidebar-logo.svg';
import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from 'state/state-hooks';
import styled from 'styled-components';
import { ACCEPT_INVITE } from 'utils/gql';

const AcceptInvite: React.FC = () => {
  useBreadCrumbUpdate(['Invitation']);
  const { token } = useParams();
  const { user } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const [acceptInvite] = useMutation<{ acceptInvite: { agencyId: string } }>(ACCEPT_INVITE);
  const [error, setError] = useState('');

  useEffect(() => {
    let isCancelled = false;
    if (user) {
      void acceptInvite({
        variables: { token, input: { email: user.attributes.email, name: `${user.attributes.given_name} ${user.attributes.family_name}` } },
      })
        .then(res => {
          navigate(`/agency/${res.data?.acceptInvite.agencyId as string}`);
        })
        .catch((err: ApolloError) => {
          if (!isCancelled) {
            setError(err.message);
          }
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [token, user]);

  return (
    <Wrapper data-testid='accept-invite__wrapper'>
      {error ? (
        <>
          <Tigers />
          <Whoops data-testid='accept-invite__whoops'>Whoops!</Whoops>
          <Message>{`${error} \nPlease contact support for help.`}</Message>
        </>
      ) : (
        <>
          <Logo data-testid='accept-invite__logo' />
          <Message>{`We are setting up your access. \nThis should only take a second...`}</Message>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 110px - 2rem);
  flex-direction: column;
  position: relative;
`;
const Logo = styled(LogoSVG)`
  width: 76px;
  margin-bottom: 43px;
`;
const Tigers = styled(TigersSVG)`
  width: 100%;
  position: absolute;
`;
const Whoops = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
`;
const Message = styled.p`
  color: ${({ theme }) => theme.colors.silver500};
  white-space: pre;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
`;
export default AcceptInvite;
