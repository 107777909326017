import { createContext, Dispatch, useContext } from 'react';

import { HeaderActions, HeaderState } from './header.reducer';

export const HeaderStateContext = createContext<HeaderState | undefined>(undefined);
export const HeaderDispatchContext = createContext<Dispatch<HeaderActions> | undefined>(undefined);

export function useHeaderState(): HeaderState {
  const context = useContext(HeaderStateContext);
  if (!context) {
    throw new Error('You did not call useHeaderState in a component that is provided for');
  }
  return context;
}

export function useHeaderDispatch(): Dispatch<HeaderActions> {
  const context = useContext(HeaderDispatchContext);
  if (!context) {
    throw new Error('You did not call useHeaderDispatch in a component that is provided for');
  }
  return context;
}
