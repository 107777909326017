import { Label, RadioInput, Wrapper } from './styles';

interface Props {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  checked: boolean;
  id: string | undefined;
}

const Radio: React.FC<Props> = ({ label, onChange, value, checked, id }) => {
  return (
    <Wrapper data-testid='radio-checkbox'>
      <RadioInput type='radio' className='radio-button' id={id} onChange={e => onChange(e)} checked={checked} value={value} />
      <Label htmlFor={id} data-testid={`radio-checkbox-input-${id}`}>
        {label}
      </Label>
    </Wrapper>
  );
};

export default Radio;
