import { useMutation } from '@apollo/client';
import { ReactComponent as Arrow } from 'assets/svg/expand-more.svg';
import { ReactComponent as Poligon } from 'assets/svg/poligon.svg';
import React, { useState } from 'react';

import { useNotificationDispatch } from '../../context/notification/notification.context';
import { showToast } from '../../context/notification/notification.reducer';
import { UserAgencyRole } from '../../enums';

import { CHANGE_USERS_ROLE } from './settings';
import { RoleWrapper, ArrowContainer, ChangeRoleContainer, ChangeRoleItem } from './styles';

interface User {
  id: number;
  role: string;
  name: string;
}

interface Agency {
  role: UserAgencyRole;
  id: number;
}

interface Props {
  user: User;
  agency: Agency;
  refetch: () => void;
}

const Role: React.FC<Props> = ({ user, agency, refetch }) => {
  const isChangeRole = ['owner', 'admin'].includes(agency.role) && user.role !== 'owner';
  const [showChangeRole, setShowChangeRole] = useState(false);
  const rolesList = ['admin', 'member'];
  const dispatch = useNotificationDispatch();
  const [changeUsersRole] = useMutation(CHANGE_USERS_ROLE);

  const handleChangeUsersRole = (roleToChange: string) => {
    void changeUsersRole({
      variables: {
        agencyId: +agency.id,
        userId: +user.id,
        role: roleToChange,
      },
    })
      .then(() => {
        dispatch(showToast(`${user.name} role has been updated to ${roleToChange}`));
        void refetch();
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        dispatch(showToast(err.message, 'error'));
      });
  };

  return (
    <RoleWrapper
      tabIndex={0}
      onClick={() => setShowChangeRole(!showChangeRole)}
      onBlur={() => setShowChangeRole(false)}
      data-testid='role-wrapper'
    >
      {user.role}
      {isChangeRole && (
        <ArrowContainer showChangeRole={showChangeRole}>
          <Arrow className='data-item__change-role-arrow' data-testid='data-item__change-role-arrow' />
          {showChangeRole && (
            <>
              <Poligon className='data-item__pointer-arrow' data-testid='data-item__pointer-arrow' />
              <ChangeRoleContainer data-testid='change-role-container'>
                {rolesList.map((item, index) => (
                  <ChangeRoleItem key={item} onClick={() => handleChangeUsersRole(item)} data-testid={`change-role-item-${index}`}>
                    {item}
                  </ChangeRoleItem>
                ))}
              </ChangeRoleContainer>
            </>
          )}
        </ArrowContainer>
      )}
    </RoleWrapper>
  );
};

export default Role;
