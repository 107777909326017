import { ReactElement } from 'react';

import { Wrapper, InputWrapper, Input, ErrorMessage, Alert, PrependWrapper } from './styles';

interface Props {
  label: string;
  value: string;
  name?: string;
  placeholder: string;
  errorText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
  type?: string;
  required?: boolean;
  width?: string;
  testid?: string;
  disabled?: boolean;
  prependIcon?: ReactElement;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
}

const TextInput: React.FC<Props> = ({
  label,
  value,
  name,
  type,
  placeholder,
  onChange,
  showError,
  errorText,
  required,
  width,
  testid,
  disabled,
  prependIcon,
  onBlur,
  autoComplete,
}) => (
  <Wrapper data-testid='text-input__wrapper' width={width}>
    {label}
    {required && '*'}
    <InputWrapper>
      {prependIcon ? <PrependWrapper>{prependIcon}</PrependWrapper> : null}
      <Input
        onBlur={onBlur}
        disabled={disabled}
        name={name}
        autoComplete={autoComplete}
        prependIcon={!!prependIcon}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        type={type ? type : 'text'}
        showError={showError}
        data-testid={testid ? testid : 'text-input__input'}
        required={required}
      />
      {showError && <Alert data-testid='text-input__alert' />}
    </InputWrapper>
    <ErrorMessage data-testid='text-input__message'>{showError && errorText}</ErrorMessage>
  </Wrapper>
);

export default TextInput;
