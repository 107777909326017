import styled, { keyframes } from 'styled-components';
import theme from '../../../styles/theme';

const radioCheckedColor = theme.colors.black;
const radioBorderColor = theme.colors.black;
const radioSize = 16;
const radioCheckedSize = 8;
const radioRippleSize = `15px`;

const ripple = keyframes`
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0);
  }

  50% {
    box-shadow: 0 0 0 ${radioRippleSize} rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0 0 0 ${radioRippleSize} rgba(0, 0, 0, 0);
  }
`;

export const Wrapper = styled.div`
  margin: 22px 0;
`;
export const RadioInput = styled.input`
  display: none;

  &:checked + label:before {
    border-color: ${radioCheckedColor};
    animation: ${ripple} 0.2s linear forwards;
  }

  &:checked + label:after {
    transform: scale(1);
  }
`;

export const Label = styled.label`
  display: inline-block;
  height: ${radioSize}px;
  position: relative;
  padding: 0 ${+radioSize + 10}px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: transform, border-color;
  }

  &:before {
    left: 0;
    top: 0;
    width: ${radioSize}px;
    height: ${radioSize}px;
    border: 2px solid ${radioBorderColor};
  }

  &:after {
    top: ${+radioSize / 2 - +radioCheckedSize / 2 + 2}px;
    left: ${+radioSize / 2 - +radioCheckedSize / 2 + 2}px;
    width: ${radioCheckedSize}px;
    height: ${radioCheckedSize}px;
    transform: scale(0);
    background: ${radioBorderColor};
  }
`;
