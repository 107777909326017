import { ToastOptions } from 'react-toastify';

import { Variables, BaseVariables } from './base-interfaces';

export const baseState: BaseVariables = {
  order: {
    field: 'name',
    sort: 'asc',
  },
  pagination: {
    page: 1,
    pageSize: 15,
  },
  search: '',
};

export const baseAgenciesState: Variables = {
  ...(baseState as BaseVariables),
  agencyId: 0,
};

export const toastSettings: ToastOptions = {
  position: 'bottom-center',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: 0,
};
