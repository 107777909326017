import React, { useState, useEffect } from 'react';
import { KioskIcon } from 'components/icons/kiosk-icon';

import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import Modal from '../../components/modal/modal';
import RoleForm from './role-form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInput from '../../components/form-elements/text-input/text-input';
import {
  FormWrapper,
  FormTitle,
  FormDescription,
  FormDisclaimer,
  FormNote,
  FormCardWrapper,
  FormCardTitle,
  FormControlsWrapper,
  SubmitBtn,
  CancelBtn,
  Form,
} from '../../styles/base';
import { errorHelper } from '../../utils/functions';

import { showToast } from '../../context/notification/notification.reducer';
import { useNotificationDispatch } from '../../context/notification/notification.context';
import { GET_AGENCY, EDIT_AGENCY } from './settings';
import { UserAgencyRole } from '../../enums';
import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';

const EditAgency: React.FC = () => {
  useBreadCrumbUpdate(['All Agencies', 'Edit Agency'], /* istanbul ignore next */ () => <KioskIcon />);
  const { agencyId } = useParams();
  const navigate = useNavigate();
  const [isUpdateRole, setIsUpdateRole] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
    onSubmit: values => {
      if (values.email === data?.agency.owner.email) {
        sendDataHandler();
      } else {
        setIsUpdateRole(true);
      }
    },
  });

  const dispatch = useNotificationDispatch();

  const { data } = useQuery<{ agency: { agency: Agency; role: UserAgencyRole; owner: DBUser } }>(GET_AGENCY, {
    variables: {
      agencyId: Number(agencyId as string),
    },
  });

  const [editAgency] = useMutation(EDIT_AGENCY);

  useEffect(() => {
    if (data) {
      formik.setFieldValue('name', data.agency.agency.name);
      formik.setFieldValue('email', data.agency.owner.email);
    }
  }, [data]);

  const sendDataHandler = (role?: string) => {
    const isRemove: boolean = role === 'remove';

    const variables = {
      agencyId: Number(agencyId as string),
      ...(formik.values.name !== data?.agency.agency.name && { newAgencyName: formik.values.name }),
      ...(formik.values.email !== data?.agency.owner.email && {
        newAgencyOwner: {
          email: formik.values.email,
          role: isRemove ? 'member' : role,
          action: isRemove ? 'remove' : 'change_role',
        },
      }),
    };

    editAgency({ variables })
      .then(({ data }) => {
        dispatch(showToast(data?.editAgency));
      })
      .catch(err => {
        dispatch(showToast(err.message, 'error'));
      })
      .finally(() => {
        navigate('/', { replace: true, state: { refetch: true } });
      });
  };

  return (
    <>
      <Modal
        title='Update Owner’s Email'
        open={isUpdateRole}
        onClose={() => {
          setIsUpdateRole(false);
        }}
      >
        {data && (
          <RoleForm email={{ currentOwnerEmail: data.agency.owner.email, newOwnerEmail: formik.values.email }} action={sendDataHandler} />
        )}
      </Modal>

      <FormWrapper data-testid='agency-edit'>
        <FormDisclaimer>
          <FormTitle>+ Edit Agency</FormTitle>
          <FormDescription>Edit an agency to help Swell partners set up their agency faster.</FormDescription>

          <FormNote>
            *Note: All fields are <b>required</b> unless specified.
          </FormNote>
        </FormDisclaimer>

        <FormCardWrapper data-testid='edit-agency__form-card-wrapper'>
          <FormCardTitle data-testid='edit-agency__title'>Agency’s Info</FormCardTitle>
          <hr />
          <Form onSubmit={formik.handleSubmit} data-testid='agency-form'>
            <TextInput
              label='Agency’s Name'
              testid='agency-name'
              {...formik.getFieldProps('name')}
              {...errorHelper(formik, 'name')}
              name='name'
              placeholder='Agency’s Name'
            />
            <TextInput
              label='Agency’s Owner Email'
              testid='agency-email'
              type='email'
              {...formik.getFieldProps('email')}
              {...errorHelper(formik, 'email')}
              name='email'
              placeholder='Agency’s Owner Email'
            />

            <FormControlsWrapper>
              <CancelBtn onClick={/* istanbul ignore next */ () => navigate('/', { replace: true })} data-testid='agency-edit-cancel'>
                Cancel
              </CancelBtn>
              <SubmitBtn type='submit' disabled={!formik.isValid} data-testid='agency-edit-submit'>
                Save
              </SubmitBtn>
            </FormControlsWrapper>
          </Form>
        </FormCardWrapper>
      </FormWrapper>
    </>
  );
};

export default EditAgency;
