import { useReducer } from 'react';

import { HeaderDispatchContext, HeaderStateContext } from './header.context';
import { headerReducer, initialHeaderState } from './header.reducer';

const HeaderProvider: React.FC = ({ children }) => {
  const [headerState, headerDispatch] = useReducer(headerReducer, initialHeaderState);

  return (
    <HeaderStateContext.Provider value={headerState}>
      <HeaderDispatchContext.Provider value={headerDispatch}>{children}</HeaderDispatchContext.Provider>
    </HeaderStateContext.Provider>
  );
};

export default HeaderProvider;
