import { ReactComponent as LogoSVG } from 'assets/svg/sidebar-logo.svg';
import { motion as m, AnimatePresence } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from 'state/state-hooks';
import styled from 'styled-components';
import { boldMedium, medium, twoXs } from 'styles/typography';
import { signOutHandler } from 'utils/sign-out';

const MasterProfile: React.FC = () => {
  const [profileOpen, setProfileOpen] = useState(false);
  const { user } = useAppSelector(state => state.auth);

  const profile = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkAndCloseProfile = (e: MouseEvent) => {
      if (profileOpen && profile.current && !profile.current.contains(e.target as Node)) setProfileOpen(false);
    };
    document.addEventListener('mousedown', checkAndCloseProfile);
    return () => {
      document.removeEventListener('mousedown', checkAndCloseProfile);
    };
  }, [profileOpen]);

  return (
    <>
      <AvatarButton
        data-testid='master-profile__avatar-button'
        onClick={() => {
          setProfileOpen(true);
        }}
      >
        {user.attributes.picture && <Image src={user.attributes.picture} alt='Profile Picture' data-testid='master-profile__image' />}
      </AvatarButton>
      <AnimatePresence>
        {profileOpen && (
          <Menu
            initial={{ x: 276 }}
            animate={{ x: 0 }}
            exit={{ x: 276 }}
            transition={{ type: 'tween', duration: 0.15 }}
            ref={profile}
            data-testid='master-profile__menu'
          >
            <Avatar>
              {user.attributes.picture && <Image src={user.attributes.picture} alt='Profile Picture' data-testid='master-profile__image' />}
            </Avatar>
            <UserName>{`${user.attributes.given_name} ${user.attributes.family_name}`}</UserName>
            {!user.attributes.isKioskUser && (
              <>
                <Link to='/profile' onClick={() => setProfileOpen(false)}>
                  <LinkItem>profile</LinkItem>
                </Link>
                <LinkItem>support</LinkItem>
              </>
            )}
            <LinkItem pink onClick={signOutHandler} data-testid='master-profile__sign-out'>
              sign out
            </LinkItem>
            {!user.attributes.isKioskUser && <Version>version {process.env.REACT_APP_VERSION}</Version>}
            <Hr />
            <GoToSwell href={process.env.REACT_APP_SWELL_WEB_URL as string}>
              <Logo />
              Go to Swell
            </GoToSwell>
            {!user.attributes.isKioskUser && <Hr />}
          </Menu>
        )}
      </AnimatePresence>
    </>
  );
};

const AvatarButton = styled.button`
  position: absolute;
  top: 17px;
  right: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  background-color: ${({ theme }) => theme.colors.silver300};
  cursor: pointer;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;
const Menu = styled(m.div)`
  width: 267px;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.silver100};
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
  padding: 46px 32px 0 34px;
`;
const Avatar = styled.div`
  width: 91px;
  height: 91px;
  background-color: ${({ theme }) => theme.colors.silver300};
  border-radius: 50%;
`;
const UserName = styled.p`
  ${boldMedium};
  margin: 15px 0 30px;
`;
const Link = styled(RouterLink)`
  text-decoration: none;
`;
const LinkItem = styled.button<{ pink?: boolean }>`
  ${medium};
  font-weight: 400;
  color: ${({ pink, theme }) => (pink ? theme.colors.darkStrawberry : theme.colors.black)};
  cursor: pointer;
  display: block;
  border: none;
  background: none;
  margin-bottom: 12px;
  padding: 0;
`;
const Version = styled.p`
  ${twoXs};
`;
const Hr = styled.hr`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.black};
  border: none;
  margin: 18px 0 0 0;
`;
const GoToSwell = styled.a`
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.silver500};
  margin: 22px 0;
  text-decoration: none;
`;
const Logo = styled(LogoSVG)`
  width: 20px;
  height: 20px;
  margin: 0 17px 0 6px;
`;
export default MasterProfile;
