import styled from 'styled-components';

import theme from './theme';
import { twoXl, small, xs } from './typography';
import { ButtonXsPink, ButtonXsWhite } from '../components/buttons';

export const BaseCard = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px 0 0 6px;
`;

// Base form components
const formContainerHeight = '460px';

interface FormProps {
  disabled: boolean;
}

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const FormTitle = styled.h2`
  ${twoXl};
  font-weight: bold;
  margin: 0;
`;

export const FormDescription = styled.p`
  ${small};
  color: ${theme.colors.silver400};
`;

export const FormNote = styled.p`
  ${xs};
`;

export const FormDisclaimer = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  min-height: ${formContainerHeight};
  padding: 25px 32px;
  margin-right: 8px;
`;

export const FormCardWrapper = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  min-height: ${formContainerHeight};
  min-width: 70%;
  padding: 34px 24px;

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${theme.colors.black};
    margin: 1em 0;
    padding: 0;
  }

  label {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`;

export const FormCardTitle = styled.p`
  ${small};
  font-weight: bold;
`;

export const FormControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const SubmitBtn = styled(ButtonXsPink)<FormProps>`
  ${props => props.disabled && 'background-color: transparent; color: gray; border: 1px solid gray'};
  margin-left: 20px;
`;

export const CancelBtn = styled(ButtonXsWhite)`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.silver300};
  color: ${theme.colors.silver500};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
