export const twoXs = `
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const xs = `
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const small = `
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const medium = `
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;

`;

export const large = `
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const xl = `
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const twoXl = `
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const threeXl = `
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const fourXl = `
    font-family: Montserrat;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const fiveXl = `
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0px;
    text-align: left;
`;

export const sixXl = `
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const sevenXl = `
    font-family: Montserrat;
    font-size: 74px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0px;
    text-align: left;
`;

export const eightXl = `
    font-family: Montserrat;
    font-size: 148px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumTwoXs = `
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumXs = `
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumSmall = `
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumMedium = `
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;

`;

export const mediumLarge = `
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumXl = `
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumTwoXl = `
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumThreeXl = `
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumFourXl = `
    font-family: Montserrat;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumFiveXl = `
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumSixXl = `
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 78px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumSevenXl = `
    font-family: Montserrat;
    font-size: 74px;
    font-style: normal;
    font-weight: 500;
    line-height: 96px;
    letter-spacing: 0px;
    text-align: left;
`;

export const mediumEightXl = `
    font-family: Montserrat;
    font-size: 148px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldTwoXs = `
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldXs = `
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldSmall = `
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldMedium = `
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;

`;

export const boldLarge = `
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldXl = `
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldTwoXl = `
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldThreeXl = `
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldFourXl = `
    font-family: Montserrat;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldFiveXl = `
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldSixXl = `
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldSevenXl = `
    font-family: Montserrat;
    font-size: 74px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;

export const boldEightXl = `
    font-family: Montserrat;
    font-size: 148px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
`;
