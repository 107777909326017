import { memo } from 'react';
import styled from 'styled-components';
import { ButtonXS } from 'styles/buttons';
import { fadeInUp } from 'styles/animations';

interface Props {
  updatePagination: (page: number) => void;
  currentPage: number;
  totalPageCount: number;
}

const Pagination: React.FC<Props> = ({ updatePagination, currentPage, totalPageCount }) => {
  const minPageCount = 6;
  let pagesList = [];

  const isMinCount = (): boolean => !!(totalPageCount && totalPageCount > minPageCount);

  const fetchPageNumbers = (): (number | string)[] => {
    if (isMinCount()) {
      const pages: (number | string)[] = [currentPage];
      if (currentPage > 1) pages.unshift(currentPage - 1);
      if (currentPage > 2) pages.unshift(1, '...');
      if (pages[0] > 1) pages.unshift(1);
      if (currentPage < totalPageCount) pages.push(currentPage + 1);
      if (pages[pages.length - 1] < totalPageCount) pages.push('...', totalPageCount);
      return pages;
    } else {
      return [...Array.from({ length: totalPageCount }, (_, i) => i + 1)];
    }
  };

  pagesList = fetchPageNumbers();

  return (
    <Wrapper data-testid='pagination'>
      <Button data-testid={'button__prev-page'} disabled={currentPage < 2} onClick={() => updatePagination(currentPage - 1)}>
        {'<'}
      </Button>

      {pagesList.map((page, index) => {
        const isMoreBtn: boolean = page === '...';
        return (
          <Button
            active={page === currentPage}
            moreBtn={isMoreBtn}
            data-testid={`button-${index}`}
            disabled={isMoreBtn}
            key={index}
            onClick={() => updatePagination(+page)}
          >
            {page}
          </Button>
        );
      })}

      <Button data-testid={'button__next-page'} disabled={currentPage === totalPageCount} onClick={() => updatePagination(currentPage + 1)}>
        {'>'}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button<{ active?: boolean; moreBtn?: boolean }>`
  ${ButtonXS};
  width: 24px;
  height: 18px;
  padding: 0;
  font-style: normal;
  margin: 0 1px;
  border: 1px solid #e9e9e9;
  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.colors.mediumPink};
    border: 1px solid ${theme.colors.mediumPink};
  `};
  ${({ moreBtn }) =>
    moreBtn &&
    `
    border: none;
    background-color: transparent;
    cursor: default;
  `};
  animation: ${({ moreBtn }) => moreBtn && fadeInUp} 300ms linear;
`;

export default memo(Pagination);
