import { ButtonXsPink, ButtonXsWhite } from 'components/buttons';
import styled from 'styled-components';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import { small, mediumSmall, mediumMedium } from 'styles/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

export const PendingInviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
`;

export const TextContainer = styled.div`
  ${small};
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  padding: 15px 0;
`;

export const InviteListContainer = styled.div<{ count: number }>`
  display: flex;
  flex-direction: column;
  overflow-y: ${({ count }) => (count > 6 ? 'scroll' : 'initial')};
  width: 100%;
  max-height: 237px;
`;

export const InviteList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
`;

export const AgencyNameCell = styled.div`
  ${mediumMedium};
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  padding: 0;
  width: 360px;
`;

export const InviteActionCell = styled.div`
  ${small};
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  padding: 0;
`;

export const AcceptInviteBtn = styled(ButtonXsPink)<{ isAccepted: boolean }>`
  ${mediumSmall};
  height: 34px;
  margin-left: 20px;
  background-color: ${({ isAccepted, theme }) => (isAccepted ? theme.colors.shuttleGray : theme.colors.mediumPink)};
  border-color: ${({ isAccepted, theme }) => (isAccepted ? theme.colors.shuttleGray : theme.colors.mediumPink)};
`;

export const DeclineInviteBtn = styled(ButtonXsWhite)`
  ${mediumSmall};
  width: 75px;
  height: 34px;
  background-color: #ffffff;
`;

export const DeclineInviteContainer = styled.div`
  ${small};
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.silver100};
  width: 100%;
  min-height: 70px;
  padding: 10px;
  margin: 10px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
`;

export const ConfirmDeclineButton = styled.button`
  ${small};
  color: ${({ theme }) => theme.colors.mediumPink};
  cursor: pointer;
  margin: 10px;
  height: auto;
  width: auto;
  background: none;
  border: none;
`;

export const AgencyNameSpan = styled.span`
  ${mediumSmall};
`;

export const Check = styled(CheckSVG)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.white};
  height: 15px;
`;
