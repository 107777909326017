import React, { ReactElement } from 'react';

import { Wrapper } from './styles';

interface Props {
  children: ReactElement[];
}

const SplitPane: React.FC<Props> = ({ children }) => <Wrapper data-testid='split-pane__wrapper'>{children}</Wrapper>;

export default SplitPane;
