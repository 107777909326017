import React, { useState } from 'react';
import { ListItem } from '../../routes/agency/users/invite-users/styles';
import { AgencyNameCell, InviteActionCell, AcceptInviteBtn, DeclineInviteBtn, Check } from './styles';
import { useMutation } from '@apollo/client';
import { useAppDispatch, useAppSelector } from 'state/state-hooks';
import { setInvitesListChangedAction } from 'state/reducers/invites-modal-reducer';
import { ACCEPT_INVITE } from 'utils/gql';

interface Props {
  invite: AgencyInvite;
  handleDecline: (invite: AgencyInvite) => void;
}

const Invite: React.FC<Props> = ({ invite, handleDecline }) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const { user } = useAppSelector(state => state.auth);
  const [acceptInvite] = useMutation(ACCEPT_INVITE);
  const dispatch = useAppDispatch();

  const acceptAgencyInvite = (invite: AgencyInvite) => {
    if (user) {
      acceptInvite({
        variables: {
          token: invite.token,
          input: { email: user.attributes.email, name: `${user.attributes.given_name} ${user.attributes.family_name}` },
        },
      }).then(() => {
        setIsAccepted(true);
        dispatch(setInvitesListChangedAction(true));
      });
    }
  };
  return (
    <ListItem data-testid='agency-invite__li'>
      <AgencyNameCell>{invite.agency?.name}</AgencyNameCell>
      <InviteActionCell>
        {!isAccepted && (
          <DeclineInviteBtn onClick={() => handleDecline(invite)} data-testid='agency-invite__decline-button'>
            Decline
          </DeclineInviteBtn>
        )}
        <AcceptInviteBtn
          onClick={() => acceptAgencyInvite(invite)}
          isAccepted={isAccepted}
          disabled={isAccepted}
          data-testid='agency-invite__accept-button'
        >
          {isAccepted ? (
            <>
              <Check data-testid='agency-invite__accepted-checkmark' />
              Accepted
            </>
          ) : (
            'Accept'
          )}
        </AcceptInviteBtn>
      </InviteActionCell>
    </ListItem>
  );
};

export default Invite;
