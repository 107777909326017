import { useHeaderDispatch } from 'context/header/header.context';
import { BreadCrumbIcon, updateBreadCrumbs } from 'context/header/header.reducer';
import { useEffect } from 'react';

function useBreadCrumbUpdate(breadcrumbs: string[], icon?: BreadCrumbIcon): void {
  const dispatch = useHeaderDispatch();
  useEffect(() => {
    dispatch(updateBreadCrumbs(breadcrumbs, icon));
  }, []);
}

export default useBreadCrumbUpdate;
