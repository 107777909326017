import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  min-height: 600px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 0 6px 6px 0;
  padding: 24px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    min-height: 10rem;
    height: auto;
    border-radius: 0 0 6px 6px;
  }
`;
