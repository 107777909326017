import { ReactComponent as ElipsesIconSVG } from 'assets/svg/elipses-icon.svg';
import { Link } from 'react-router-dom';
import { fadeIn } from '../../styles/animations';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AllAgenciesReturn } from './interfaces';
import theme from '../../styles/theme';

interface Props {
  agency: AllAgenciesReturn;
  currentPage: number;
}

const Actions: React.FC<Props> = ({ agency, currentPage }) => {
  const [actionsVisible, setActionsVisible] = useState(false);

  return (
    <Wrapper>
      <ElipsesIcon onClick={() => setActionsVisible(!actionsVisible)} data-testid='actions__elipses' />

      {actionsVisible && (
        <ActionsContainer onMouseLeave={() => setActionsVisible(false)} data-testid='actions__actions-container'>
          <Link to={`/edit-agency/${agency.id}/`} state={{ currentPage }}>
            Edit
          </Link>
        </ActionsContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #b7b7b7;
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
`;
const ElipsesIcon = styled(ElipsesIconSVG)`
  cursor: pointer;
`;

export const ActionsContainer = styled.div`
  position: absolute;
  width: 106px;
  background: white;
  left: -80px;
  top: 30px;
  padding: 16px 24px;
  z-index: 1;
  animation: ${fadeIn} 300ms linear;
  :after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: 25px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  a {
    color: ${theme.colors.mediumStrawberry};
  }
`;

export default Actions;
