import useBreadCrumbUpdate from 'hooks/useBreadCrumbUpdate';
import { useAppSelector } from 'state/state-hooks';
import styled from 'styled-components';
import { boldTwoXl, mediumLarge, small } from 'styles/typography';
import { signOutHandler } from 'utils/sign-out';

import EditProfile from './edit-profile';

const Profile: React.FC = () => {
  const { user } = useAppSelector(state => state.auth);

  useBreadCrumbUpdate(['Profile']);

  return (
    <Wrapper data-testid='profile__wrapper'>
      <Details>
        <Title>Profile</Title>
        <ProfilePicture>
          {user.attributes.picture && <Image src={user.attributes.picture} alt='' data-testid='profile__image' />}
        </ProfilePicture>
        <Name>{`${user.attributes.given_name} ${user.attributes.family_name}`}</Name>
        <Row>{user.attributes.email}</Row>
        <Row>{user.attributes.phone_number}</Row>
        <Row>
          <Button>reset password</Button>
        </Row>
        <Row>
          <Button red data-testid='profile__sign-out-button' onClick={signOutHandler}>
            sign out
          </Button>
        </Row>
      </Details>
      <EditProfile user={user} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
`;
const Details = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: block;
  height: 600px;
  width: 323px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.05);
  padding: 32px;
  margin-right: 8px;
`;
const Title = styled.p`
  ${boldTwoXl};
  margin-bottom: 36px;
`;
const ProfilePicture = styled.div`
  width: 135px;
  height: 135px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.silver300};
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
const Name = styled.p`
  ${mediumLarge};
  margin-bottom: 12px;
`;
const Row = styled.p`
  ${small};
  margin-bottom: 6px;
`;
const Button = styled.button<{ red?: boolean }>`
  border: none;
  background: transparent;
  padding: 0;
  text-decoration: underline;
  display: block;
  color: ${({ theme, red }) => (red ? theme.colors.darkStrawberry : theme.colors.silver500)};
  cursor: pointer;
`;

export default Profile;
