import { createAction, createReducer } from '@reduxjs/toolkit';
import { CognitoPasswordRequirements } from '../../utils/password-validation/password-validation.types';
export const setPasswordValidationRules = createAction<CognitoPasswordRequirements>('SET_PASSWORD_VALIDATION_RULES');

export interface PasswordValidationRulesState {
  rules: CognitoPasswordRequirements | Record<string, never>;
  isLoaded: boolean;
}

export const initialState: PasswordValidationRulesState = {
  isLoaded: false,
  rules: {},
};

export const passwordValidationRulesReducer = createReducer(initialState, builder => {
  builder.addCase(setPasswordValidationRules, (state, action) => {
    state.isLoaded = true;
    state.rules = action.payload;
  });
});
