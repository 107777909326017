import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
0% {
    transform: translateX(-75%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(175%);
  }
`;

export const Svg = styled.svg`
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 4px / 6.7px;
  display: block;
`;

const SmallBar = styled.rect`
  animation: ${pulse} 1.25s ease-in-out infinite;
`;
export const Skeleton: React.FC<{ width: string; height: string }> = ({ width, height }) => (
  <Svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' data-testid='skeleton__wrapper'>
    <defs>
      <linearGradient id='grad1' x1='0%' y1='0%' x2='100%' y2='0%'>
        <stop offset='0%' style={{ stopColor: 'rgb(225,225,225)', stopOpacity: 0 }} />
        <stop offset='50%' style={{ stopColor: 'rgb(225,225,225)', stopOpacity: 1 }} />
        <stop offset='100%' style={{ stopColor: 'rgb(225,225,225)', stopOpacity: 0 }} />
      </linearGradient>
    </defs>
    <rect id='bar' fill='rgb(205,205,205)' width='100%' height='100%' rx='5' ry='5' />
    <SmallBar fill='url(#grad1)' width='70%' height='100%' />
  </Svg>
);
