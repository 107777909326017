import { Skeleton } from 'components/skeleton/skeleton';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { mediumXs } from 'styles/typography';

interface TableColumn {
  header: ReactElement;
  component: (cellData: any) => ReactElement;
  columnStyles: string;
}

interface Props {
  columns: TableColumn[];
  data: unknown[];
  loading: boolean;
}

const DataTable: React.FC<Props> = ({ loading, data, columns }) => {
  return (
    <Wrapper data-testid='data-table__wrapper'>
      <HeadingWrapper>
        {columns.map(({ header, columnStyles }, i) => (
          <HeaderCell key={`header-${i}`} columnStyles={columnStyles} data-testid='data-table__header'>
            {header}
          </HeaderCell>
        ))}
      </HeadingWrapper>

      <BodyWrapper>
        {loading ? (
          <>
            <Skeleton width='50%' height='48px' />
            <Skeleton width='30%' height='48px' />
            <Skeleton width='65%' height='48px' />
            <Skeleton width='20%' height='48px' />
            <Skeleton width='35%' height='48px' />
          </>
        ) : data.length ? (
          data.map((columnData, i) => (
            <TableRow key={`row-${i}`} id={`row-${i}`}>
              {columns.map(({ component, columnStyles }, i) => (
                <TableCell key={`cell-${i}`} columnStyles={columnStyles} id={`cell-${i}`} data-testid='data-table__cell'>
                  {component(columnData)}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <p>There is no data</p>
        )}
      </BodyWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
`;
const HeadingWrapper = styled.div`
  height: 49px;
  border-top: solid 1px ${({ theme }) => theme.colors.slateExtraLight};
  display: flex;
  justify-content: space-between;
`;
const HeaderCell = styled.div<{ columnStyles: string }>`
  display: flex;
  align-items: center;
  ${({ columnStyles }) => columnStyles};
`;

const BodyWrapper = styled.ul`
  padding: 0;
  margin: 0;
`;

const TableRow = styled.li`
  height: 68px;
  border-top: solid 1px ${({ theme }) => theme.colors.slateExtraLight};
  display: flex;
  justify-content: space-between;
`;

const TableCell = styled.div<{ columnStyles: string }>`
  height: 100%;
  display: flex;
  align-items: center;
  ${({ columnStyles }) => columnStyles};
  ${mediumXs}
`;

export default DataTable;
