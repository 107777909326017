import { gql } from '@apollo/client';

export const GET_ALL_AGENCIES = gql`
  query allAgencies($order: BaseOrder, $pagination: PaginatedInput, $search: String) {
    allAgencies(order: $order, pagination: $pagination, search: $search) {
      data {
        id
        name
        createdAt
        ownerName
        numUsers
        numCompanies
      }
      count
    }
  }
`;
