import styled from 'styled-components';
import { boldSmall } from 'styles/typography';

export const HeaderWrapper = styled.div`
  display: flex;
  margin: 15px 0;
  border-bottom: 2px solid #131313;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const HeaderText = styled.h2`
  ${boldSmall};
`;
