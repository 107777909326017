import { useState } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import useDebounce from '../../hooks/react-use/useDebounce';
import TextInput from '../form-elements/text-input/text-input';

import { SearchWrapper } from './styles';

interface Props {
  search?: (value: string) => void;
  dataTestId?: string;
}

const Search: React.FC<Props> = ({ search, dataTestId }) => {
  const [inputValue, setSearch] = useState('');
  const [, setDebouncedValue] = useState('');

  const [,] = useDebounce(
    () => {
      setDebouncedValue(inputValue);
      if (search) {
        search(inputValue);
      }
    },
    200,
    [inputValue]
  );

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
  };

  return (
    <SearchWrapper data-testid={dataTestId}>
      <TextInput
        prependIcon={<SearchIcon />}
        width={'319px'}
        label={''}
        value={inputValue}
        placeholder={''}
        errorText={''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          updateSearch(event);
        }}
        testid={'search__input'}
      />
    </SearchWrapper>
  );
};

export default Search;
