import { gql } from '@apollo/client';

export const GET_AGENCY = gql`
  query getAgency($agencyId: Int!) {
    agency(id: $agencyId) {
      agency {
        name
        id
      }
      role
      owner {
        email
        id
      }
    }
  }
`;

export const EDIT_AGENCY = gql`
  mutation editAgency($agencyId: Int!, $newAgencyName: String, $newAgencyOwner: NewAgencyOwner) {
    editAgency(agencyId: $agencyId, newAgencyName: $newAgencyName, newAgencyOwner: $newAgencyOwner)
  }
`;

export interface CustomizedState {
  currentPage: number;
}
