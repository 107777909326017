import { BreadCrumbIcon } from 'context/header/header.reducer';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { xl } from 'styles/typography';

interface Props {
  breadcrumbs: string[];
  Icon: BreadCrumbIcon;
}

const BreadCrumbs: React.FC<Props> = ({ breadcrumbs, Icon }) => (
  <Wrapper data-testid='breadcrumbs__wrapper'>
    <Link to='/' aria-label='Home'>
      <Icon />
    </Link>
    {breadcrumbs.length > 0 && (
      <Divider light data-testid='breadcrumbs__divider'>
        /
      </Divider>
    )}
    {breadcrumbs.map((crumb, i) => (
      <React.Fragment key={i}>
        {!(i === breadcrumbs.length || i === 0) && <Divider data-testid='breadcrumbs__divider'>/</Divider>}
        <DisplayItem data-testid='breadcrumbs__crumb'>{crumb}</DisplayItem>
      </React.Fragment>
    ))}
  </Wrapper>
);

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  ${xl}
  a {
    display: flex;
    align-items: center;
  }
`;
const DisplayItem = styled.div`
  margin: 0 4px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
`;
// const LinkItem = styled(Link)`
//   cursor: pointer;
//   margin: 0 4px;
//   color: ${({ theme }) => theme.colors.black};
//   text-decoration: none;
// `;

const Divider = styled.span<{ light?: boolean }>`
  margin: 0 4px;
  color: ${({ theme, light }) => (light ? theme.colors.silver400 : theme.colors.black)};
`;

export default BreadCrumbs;
