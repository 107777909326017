import styled from 'styled-components';
import { xs, small, mediumSmall, twoXs } from 'styles/typography';

import { ReactComponent as CancelIcon } from '../../../../assets/svg/cancel-icon.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/svg/link-icon.svg';
import { ReactComponent as SendIcon } from '../../../../assets/svg/send-icon.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

export const NewInviteContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const PendingInviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
`;

export const TitleContainer = styled.div`
  ${xs};
  display: flex;
  color: ${({ theme }) => theme.colors.silver400};
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.silver400};
`;

export const InviteListContainer = styled.div<{ count: number }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.silver400};
  overflow-y: ${({ count }) => (count > 6 ? 'scroll' : 'initial')};
  width: 100%;
  max-height: 237px;
`;

export const InviteList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
`;

export const NoDataMessage = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.silver500};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ItemEmailCell = styled.div`
  ${small};
  display: block;
  color: ${({ theme }) => theme.colors.silver500};
  width: 185px;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemRoleCell = styled.div`
  ${small};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  color: ${({ theme }) => theme.colors.black};
`;

export const ItemResendInviteCell = styled.div`
  ${small};
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  width: 120px;
`;

export const ItemCopyLinkCell = styled.div`
  ${small};
  display: flex;
  justify-content: end;
  align-items: center;
  color: ${({ theme }) => theme.colors.mediumPink};
  width: 100px;
`;

export const RemoveButton = styled.button`
  height: 18px;
  width: auto;
  background: none;
  border: none;
`;

export const StyledCancelIcon = styled(CancelIcon)`
  color: ${({ theme }) => theme.colors.silver500};
  margin-right: 5px;
  cursor: pointer;
`;

export const StyledLinkIcon = styled(LinkIcon)`
  margin-right: 5px;
`;

export const StyledSendIcon = styled(SendIcon)`
  margin-right: 5px;
`;

export const DeleteInviteContainer = styled.div`
  ${small};
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.silver100};
  width: 100%;
  min-height: 70px;
  padding: 5px;
  margin: 10px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
`;

export const DeleteButton = styled.button`
  ${mediumSmall};
  color: ${({ theme }) => theme.colors.mediumPink};
  cursor: pointer;
  margin: 10px;
  height: auto;
  width: auto;
  background: none;
  border: none;
`;

export const CopyLinkButton = styled.button<{ isInviteCopied: boolean }>`
  color: ${({ isInviteCopied, theme }) => (isInviteCopied ? theme.colors.silver500 : theme.colors.mediumPink)};
  cursor: pointer;
  margin: 0;
  height: auto;
  width: auto;
  background: none;
  border: none;
  text-decoration: ${({ isInviteCopied }) => (isInviteCopied ? 'none' : 'underline')};
`;

export const ResendInviteButton = styled.button<{ isInviteResent: boolean }>`
  color: ${({ isInviteResent, theme }) => (isInviteResent ? theme.colors.silver500 : theme.colors.black)};
  cursor: ${({ isInviteResent }) => (isInviteResent ? 'default' : 'pointer')};
  margin: 0;
  height: auto;
  width: auto;
  background: none;
  border: none;
  text-decoration: ${({ isInviteResent }) => (isInviteResent ? 'none' : 'underline')};
  padding: 0;
`;

export const ResendInviteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: #b7b7b7;
  position: relative;
  border: none;
  padding: 0;
  background: transparent;
`;

export const Tooltip = styled.div<{ showTooltip: boolean; index: number }>`
  ${twoXs}
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  height: 22px;
  width: 252px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  visibility: ${({ showTooltip }) => (showTooltip ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 1;
  left: -100px;
  top: ${({ index }) => (index < 1 ? '20px' : '-30px')};
`;
