import { ButtonXsPink } from 'components/buttons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;
export const AgencyLink = styled(Link)`
  color: black;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateAgencyBtn = styled(ButtonXsPink)`
  min-width: 128px;
`;
